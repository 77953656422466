import React, { useState } from "react";
import { PDFDocument, rgb, degrees } from "pdf-lib";
import Popup from "reactjs-popup";
import fontkit from "@pdf-lib/fontkit";
import download from "downloadjs";
import OFP from "./PDFTemplates/OFPAUTO.pdf";
import fuente from "./Fonts/Calibri.ttf";
import fuentebold from "./Fonts/calibrib.ttf";
import rutas from "./Rutas.js";
import everpolate from "everpolate";
import VerticalProfile from "./PDFTemplates/VerticalProfile.pdf";

//FALTA AÑADIR EL FUELFLOW
export default function OFPDA40({
  mapValues,
  routeParameters,
  currentRoute,
  modelData,
  departureTime,
}) {
  const [isSending, setIsSending] = useState(false);
  const [disableMap, setDisableMap] = useState(false);

  async function handleClick(e) {
    const PDFtoDownload = e.target.id
    e.preventDefault();
    if (isSending) return;
    setIsSending(true);
    await modifyPdf(PDFtoDownload);
    setIsSending(false);
  }

  function parseDegrees(number) {
    var numero = number.toString();

    if (numero.length === 1) {
      numero = "00" + numero;
    } else if (numero.length === 2) {
      numero = "0" + numero;
    }
    return numero;
  }

  async function getData(url) {
    const response = await fetch(url);

    return response.json();
  }

  function windDirectionInterpolator(number, arrayX, arrayY) {
    let windDirectionInterpolated = [];
    const index = Number(
      arrayX
        .map((x) => Math.abs(number - x))
        .findIndex(
          (x) => x === Math.min(...arrayX.map((x) => Math.abs(number - x)))
        )
    );
    const valor = Number(everpolate.linear(number, arrayX, arrayY));

    if (Math.abs(arrayY[index + 1] - arrayY[index]) > 180) {
      if (valor + 180 > 360) {
        windDirectionInterpolated = valor - 180;
      } else {
        windDirectionInterpolated = valor + 180;
      }
    } else {
      windDirectionInterpolated = valor;
    }

    return windDirectionInterpolated;
  }

  async function modifyPdf(PDFtoDownload) {
    mapValues = { ...mapValues };
    routeParameters = { ...routeParameters };

    const DEPTemp =
      Number(routeParameters.routeTemperature) ?? mapValues.temperature[0];
    const DEPElev = Number(routeParameters.departureElevation);
    const AlternateElev = Number(routeParameters.alternativeElevation);
    const AlternateTIME = 10; //routeParameters.alternativeTime;
    const AlternateFUEL = 2; //routeParameters.alternativeFuel;
    const TaxiRunUpFUEL = 2.5 * (15 / 60) + 8 * (2.5 / 60) + 15 * (2.5 / 60);
    const FOB = 40.2;

    var DA = [];

    for (var i = 0; i < mapValues.altitudes.length; i++) {
      DA[i] =
        mapValues.altitudes[i] + (1013 - routeParameters.departureQNH) * 27;
    }
    DA.unshift(DEPElev + (1013 - routeParameters.departureQNH) * 27);

    Object.keys(routeParameters).forEach((param) => {
      routeParameters[param] = Number(routeParameters[param]);
    });

    var selectedRouteCoordinates = currentRoute
      ? Object.keys(rutas[currentRoute]).map(
          (point) => rutas[currentRoute][point]["COORDINATES"]
        )
      : mapValues.coordinates.slice(0, -1);

    var selectedRouteAltitudes = currentRoute
      ? Object.keys(rutas[currentRoute]).map(
          (point) => rutas[currentRoute][point]["ALTITUDE"]
        )
      : DA;

    var allLatitudes = Object.keys(selectedRouteCoordinates).map(
      (point) => selectedRouteCoordinates[point]["lat"]
    );
    var allLongitudes = Object.keys(selectedRouteCoordinates).map(
      (point) => selectedRouteCoordinates[point]["lng"]
    );

    var allLatitudesIntheMiddle = [];
    var allLongitudesIntheMiddle = [];

    for (var i = 0; i < allLatitudes.length - 1; i++) {
      allLatitudesIntheMiddle.push((allLatitudes[i] + allLatitudes[i + 1]) / 2);
      allLongitudesIntheMiddle.push(
        (allLongitudes[i] + allLongitudes[i + 1]) / 2
      );
    }

    var middlePointCoordinates = allLatitudesIntheMiddle.map(function (x, i) {
      return [x, allLongitudesIntheMiddle[i]];
    });

    var middleAltitudes = [];
    for (var i = 0; i < selectedRouteAltitudes.length - 1; i++) {
      if (selectedRouteAltitudes[i] < selectedRouteAltitudes[i + 1]) {
        middleAltitudes[i] =
          selectedRouteAltitudes[i] +
          (selectedRouteAltitudes[i + 1] - selectedRouteAltitudes[i]) * (2 / 3);
      } else if (selectedRouteAltitudes[i] > selectedRouteAltitudes[i + 1]) {
        middleAltitudes[i] =
          selectedRouteAltitudes[i] +
          (selectedRouteAltitudes[i + 1] - selectedRouteAltitudes[i]) * (1 / 2);
      } else {
        middleAltitudes[i] = selectedRouteAltitudes[i];
      }
    }

    var timeOfETD = new Date(departureTime).getTime() / 1000;

    const openMeteoAltitudes = [
      0.11, 0.32, 0.5, 0.8, 1, 1.5, 1.9, 3, 4.2, 5.6, 7.2,
    ].map((x) => x * 3280.84);

    const dividedNum = (num) => 3600 * (num / 110);
    const timeDeltas = mapValues.distances.map(dividedNum);

    let WindSpeedPredicted = [];
    let WindDirectionPredicted = [];

    for (var j = 0; j < middleAltitudes.length; j++) {
      let timeOfSurvey =
        timeOfETD + timeDeltas.reduce((a, b, i) => (i < j ? a + b : a), 0);
      let altitudeOfSurvey = middleAltitudes[j];

      var url =
        "https://api.open-meteo.com/v1/forecast?latitude=" +
        middlePointCoordinates[j][0] +
        "&longitude=" +
        middlePointCoordinates[j][1] +
        "&windspeed_unit=kn&hourly=windspeed_1000hPa,windspeed_975hPa,windspeed_950hPa,windspeed_925hPa,windspeed_900hPa,windspeed_850hPa,windspeed_800hPa,windspeed_700hPa,windspeed_600hPa,windspeed_500hPa,windspeed_400hPa,winddirection_1000hPa,winddirection_975hPa,winddirection_950hPa,winddirection_925hPa,winddirection_900hPa,winddirection_850hPa,winddirection_800hPa,winddirection_700hPa,winddirection_600hPa,winddirection_500hPa,winddirection_400hPa&timeformat=unixtime" +
        "&start_date=" +
        String(departureTime.substr(0, 10)) +
        "&end_date=" +
        String(departureTime.substr(0, 10));

      const data = await getData(url);

      WindSpeedPredicted.push(
        Number(
          everpolate.linear(altitudeOfSurvey, openMeteoAltitudes, [
            Number(
              everpolate.linear(
                timeOfSurvey,
                data.hourly.time,
                data.hourly.windspeed_1000hPa
              )
            ),
            Number(
              everpolate.linear(
                timeOfSurvey,
                data.hourly.time,
                data.hourly.windspeed_975hPa
              )
            ),
            Number(
              everpolate.linear(
                timeOfSurvey,
                data.hourly.time,
                data.hourly.windspeed_950hPa
              )
            ),
            Number(
              everpolate.linear(
                timeOfSurvey,
                data.hourly.time,
                data.hourly.windspeed_925hPa
              )
            ),
            Number(
              everpolate.linear(
                timeOfSurvey,
                data.hourly.time,
                data.hourly.windspeed_900hPa
              )
            ),
            Number(
              everpolate.linear(
                timeOfSurvey,
                data.hourly.time,
                data.hourly.windspeed_850hPa
              )
            ),
            Number(
              everpolate.linear(
                timeOfSurvey,
                data.hourly.time,
                data.hourly.windspeed_800hPa
              )
            ),
            Number(
              everpolate.linear(
                timeOfSurvey,
                data.hourly.time,
                data.hourly.windspeed_700hPa
              )
            ),
            Number(
              everpolate.linear(
                timeOfSurvey,
                data.hourly.time,
                data.hourly.windspeed_600hPa
              )
            ),
            Number(
              everpolate.linear(
                timeOfSurvey,
                data.hourly.time,
                data.hourly.windspeed_500hPa
              )
            ),
            Number(
              everpolate.linear(
                timeOfSurvey,
                data.hourly.time,
                data.hourly.windspeed_400hPa
              )
            ),
          ])
        )
      );

      WindDirectionPredicted.push(
        Number(
          windDirectionInterpolator(altitudeOfSurvey, openMeteoAltitudes, [
            Number(
              windDirectionInterpolator(
                timeOfSurvey,
                data.hourly.time,
                data.hourly.winddirection_1000hPa
              )
            ),
            Number(
              windDirectionInterpolator(
                timeOfSurvey,
                data.hourly.time,
                data.hourly.winddirection_975hPa
              )
            ),
            Number(
              windDirectionInterpolator(
                timeOfSurvey,
                data.hourly.time,
                data.hourly.winddirection_950hPa
              )
            ),
            Number(
              windDirectionInterpolator(
                timeOfSurvey,
                data.hourly.time,
                data.hourly.winddirection_925hPa
              )
            ),
            Number(
              windDirectionInterpolator(
                timeOfSurvey,
                data.hourly.time,
                data.hourly.winddirection_900hPa
              )
            ),
            Number(
              windDirectionInterpolator(
                timeOfSurvey,
                data.hourly.time,
                data.hourly.winddirection_850hPa
              )
            ),
            Number(
              windDirectionInterpolator(
                timeOfSurvey,
                data.hourly.time,
                data.hourly.winddirection_800hPa
              )
            ),
            Number(
              windDirectionInterpolator(
                timeOfSurvey,
                data.hourly.time,
                data.hourly.winddirection_700hPa
              )
            ),
            Number(
              windDirectionInterpolator(
                timeOfSurvey,
                data.hourly.time,
                data.hourly.winddirection_600hPa
              )
            ),
            Number(
              windDirectionInterpolator(
                timeOfSurvey,
                data.hourly.time,
                data.hourly.winddirection_500hPa
              )
            ),
            Number(
              windDirectionInterpolator(
                timeOfSurvey,
                data.hourly.time,
                data.hourly.winddirection_400hPa
              )
            ),
          ])
        )
      );
    }

    Object.keys(mapValues).forEach((element) => {
      mapValues[element] = mapValues[element].map((value) => {
        if (
          (isNaN(Number(value)) && typeof value !== "string") ||
          value === "0"
        ) {
          return 0;
        } else {
          return value;
        }
      });
    });

    mapValues.magneticVariation = routeParameters.routeMagneticVariation
      ? mapValues.distances.map((e) => routeParameters.routeMagneticVariation)
      : mapValues.distances.map((e, i) =>
          mapValues.magneticVariation[i] === undefined
            ? 0
            : mapValues.magneticVariation[i]
        );

    mapValues.windDirection = routeParameters.routeWindDirection
      ? mapValues.distances.map((e) => routeParameters.routeWindDirection)
      : mapValues.distances.map((e, i) =>
          mapValues.windDirection[i] === undefined
            ? WindDirectionPredicted.map((ele) => ele.toFixed(0))[i]
            : mapValues.windDirection[i]
        );

    mapValues.windSpeed = routeParameters.routeWindSpeed
      ? mapValues.distances.map((e) => routeParameters.routeWindSpeed)
      : mapValues.distances.map((e, i) =>
          mapValues.windSpeed[i] === undefined
            ? WindSpeedPredicted.map((ele) => ele.toFixed(0))[i]
            : mapValues.windSpeed[i]
        );

    mapValues.temperature = routeParameters.routeTemperature
      ? mapValues.distances.map((e) => routeParameters.routeTemperature)
      : mapValues.distances.map((e, i) =>
          mapValues.temperature[i] === undefined ? 0 : mapValues.temperature[i]
        );

    for (var i = 1; i < DA.length; i++) {
      if (DA[i] > DA[i - 1]) {
        DA.splice(i + 1, 0, DA[i]);
        mapValues.waypoints.splice(i, 0, "TOC");
        mapValues.bearings.splice(i - 1, 0, mapValues.bearings[i - 1]);
        mapValues.distances.splice(i - 1, 0, mapValues.distances[i - 1]);
        mapValues.altitudes.splice(i - 1, 0, mapValues.altitudes[i - 1]);
        mapValues.magneticVariation.splice(
          i - 1,
          0,
          mapValues.magneticVariation[i - 1]
        );
        mapValues.windDirection.splice(
          i - 1,
          0,
          mapValues.windDirection[i - 1]
        );
        mapValues.windSpeed.splice(i - 1, 0, mapValues.windSpeed[i - 1]);
        i++;
      } else if (DA[i] < DA[i - 1]) {
        DA.splice(i - 1, 0, DA[i - 1]);
        mapValues.waypoints.splice(i, 0, "TOD");
        mapValues.bearings.splice(i - 1, 0, mapValues.bearings[i - 1]);
        mapValues.distances.splice(i - 1, 0, mapValues.distances[i - 1]);
        mapValues.altitudes.splice(i - 1, 0, mapValues.altitudes[i - 2]);
        mapValues.magneticVariation.splice(
          i - 1,
          0,
          mapValues.magneticVariation[i - 1]
        );
        mapValues.windDirection.splice(
          i - 1,
          0,
          mapValues.windDirection[i - 1]
        );
        mapValues.windSpeed.splice(i - 1, 0, mapValues.windSpeed[i - 1]);
        i++;
      }
    }

    var CLBorDESC = [];
    for (var i = 1; i < DA.length; i++) {
      if (DA[i] > DA[i - 1]) {
        CLBorDESC[i - 1] = "CLB";
      } else if (DA[i] < DA[i - 1]) {
        CLBorDESC[i - 1] = "DESC";
      } else {
        CLBorDESC[i - 1] = "CRZ";
      }
    }

    var TAS = [];
    for (var j = 0; j < DA.length; j++) {
      if (CLBorDESC[j] === "CLB") {
        TAS[j] =
          75 + (0.02 * 75 * (DA[j] + (2 / 3) * (DA[j + 1] - DA[j]))) / 1000;
      } else if (CLBorDESC[j] === "DESC") {
        TAS[j] =
          110 +
          (0.02 * 110 * (DA[j + 1] + (1 / 2) * (DA[j] - DA[j + 1]))) / 1000;
      } else {
        TAS[j] = 110 + (0.02 * 110 * DA[j]) / 1000;
      }
    }

    var GS = [];
    for (var k = 0; k < CLBorDESC.length; k++) {
      GS[k] =
        mapValues.windSpeed[k] *
          Math.cos(
            ((180 -
              Math.abs(mapValues.bearings[k] - mapValues.windDirection[k])) *
              Math.PI) /
              180
          ) +
        Math.sqrt(
          TAS[k] ** 2 -
            (mapValues.windSpeed[k] *
              Math.sin(
                ((180 -
                  Math.abs(
                    mapValues.bearings[k] - mapValues.windDirection[k]
                  )) *
                  Math.PI) /
                  180
              )) **
              2
        );
    }

    var MC = [];
    for (var k = 0; k < CLBorDESC.length; k++) {
      MC[k] = mapValues.bearings[k] + mapValues.magneticVariation[k];
    }

    var MH = [];
    for (var k = 0; k < CLBorDESC.length; k++) {
      MH[k] = Math.round(
        (180 / Math.PI) *
          Math.atan2(
            GS[k] * Math.sin((MC[k] * Math.PI) / 180) +
              mapValues.windSpeed[k] *
                Math.sin((mapValues.windDirection[k] * Math.PI) / 180),
            GS[k] * Math.cos((MC[k] * Math.PI) / 180) +
              mapValues.windSpeed[k] *
                Math.cos((mapValues.windDirection[k] * Math.PI) / 180)
          )
      );
      if (MH[k] < 0) {
        MH[k] = MH[k] + 360;
      }
    }

    /// DE AQUÍ PARA ARRIBA ES IGUAL PARA TODOS LOS MODELOS DE AVIÓN.
    /// DE AQUÍ PARA ABAJO ES ESPECÍFICO DE LA DA40.

    var EET = [];
    for (var k = 0; k < CLBorDESC.length; k++) {
      if (CLBorDESC[k] === "CLB") {
        EET[k] =
          (DA[k + 1] - DA[k]) /
          ((60 / 0.3048) *
            (((-(1449 / 1636) *
              (DA[k] +
                (DA[k + 1] - DA[k]) * (2 / 3) -
                ((1 / 2) *
                  (30 -
                    (DEPTemp -
                      (1.98 * (DA[k] - DA[0] + ((DA[k + 1] - DA[k]) * 2) / 3)) /
                        1000)) *
                  2000) /
                  10) +
              (483 * 1625) / 78) *
              409) /
              1066625));
        mapValues.distances[k] = (GS[k] * EET[k]) / 60;
        mapValues.distances[k + 1] =
          mapValues.distances[k + 1] - mapValues.distances[k];
      } else if (CLBorDESC[k] === "DESC") {
        EET[k] = (DA[k] - DA[k + 1]) / 500;
        mapValues.distances[k] = (GS[k] * EET[k]) / 60;
        mapValues.distances[k - 1] =
          mapValues.distances[k - 1] - mapValues.distances[k];
          EET[k-1] = (60 * mapValues.distances[k-1]) / GS[k-1];
      } else {
        EET[k] = (60 * mapValues.distances[k]) / GS[k];
      }
    }

    var EST = [];
    for (var k = 0; k < CLBorDESC.length; k++) {
      if (CLBorDESC[k] === "CLB") {
        EST[k] = (13 * EET[k]) / 60;
      } else {
        EST[k] = (12 * EET[k]) / 60;
      }
    }

    var TotalDist = [];
    const chunkSize = 13;
    var sumaanterior = 0;
    for (let i = 0; i < mapValues.distances.length; i += chunkSize) {
      const chunk = mapValues.distances.slice(i, i + chunkSize);
      const sumadistancias = chunk.reduce((partialSum, a) => partialSum + a, 0);
      TotalDist.push(sumadistancias + sumaanterior);
      var sumaanterior = sumadistancias;
    }

    var TotalEET = [];
    var sumaanterior = 0;
    for (let i = 0; i < EET.length; i += chunkSize) {
      const chunk = EET.slice(i, i + chunkSize);
      const sumaEET = chunk.reduce((partialSum, a) => partialSum + a, 0);
      TotalEET.push(sumaEET + sumaanterior);
      var sumaanterior = sumaEET;
    }

    var TotalEST = [];
    var sumaanterior = 0;
    for (let i = 0; i < EET.length; i += chunkSize) {
      const chunk = EST.slice(i, i + chunkSize);
      const sumaEST = chunk.reduce((partialSum, a) => partialSum + a, 0);
      TotalEST.push(sumaEST + sumaanterior);
      var sumaanterior = sumaEST;
    }

    var TIMEs = [[], [], [], []];
    for (var j = 0; j < CLBorDESC.length; j++) {
      if (CLBorDESC[j] === "CLB") {
        TIMEs[0].push(EET[j]);
      } else if (CLBorDESC[j] === "DESC") {
        TIMEs[2].push(EET[j]);
      } else {
        TIMEs[1].push(EET[j]);
      }
    }

    // if (mapValues.waypoints[mapValues.waypoints.length - 1] === "APP") {
    //   TIMEs[3].push(EET[CLBorDESC.length-1])
    // }

    TIMEs[3].push(EET[CLBorDESC.length - 1]);

    var FUELs = [[], [], [], []];
    for (var j = 0; j < CLBorDESC.length; j++) {
      if (CLBorDESC[j] === "CLB") {
        FUELs[0].push(EST[j]);
      } else if (CLBorDESC[j] === "DESC") {
        FUELs[2].push(EST[j]);
      } else {
        FUELs[1].push(EST[j]);
      }
    }

    // if (mapValues.waypoints[mapValues.waypoints.length - 1] === "APP") {
    //   FUELs[3].push(EST[CLBorDESC.length-1])
    // }

    FUELs[3].push(EST[CLBorDESC.length - 1]);

    var TotalTIME = [];
    for (var j = 0; j < TIMEs.length; j++) {
      TotalTIME.push(TIMEs[j].reduce((partialSum, a) => partialSum + a, 0));
    }

    TotalTIME[2] = TotalTIME[2] - TotalTIME[3];

    var TotalFUEL = [];
    for (var j = 0; j < FUELs.length; j++) {
      TotalFUEL.push(FUELs[j].reduce((partialSum, a) => partialSum + a, 0));
    }

    TotalFUEL[2] = TotalFUEL[2] - TotalFUEL[3];

    var ReserveFUEL = [
      0,
      AlternateFUEL,
      (12 * 45) / 60,
      "------",
      0,
      TotalEST[TotalEST.length - 1],
      TaxiRunUpFUEL,
      0,
      0,
      FOB,
    ];

    var ReserveTIME = [0, AlternateTIME, 45, "------"];

    if ((12 * 5) / 60 > 0.05 * TotalEST[TotalEST.length - 1]) {
      ReserveFUEL[0] = (12 * 5) / 60;
      ReserveTIME[0] = 5;
    } else if ((12 * 5) / 60 < 0.05 * TotalEST[TotalEST.length - 1]) {
      ReserveFUEL[0] = 0.05 * TotalEST[TotalEST.length - 1];
      ReserveTIME[0] = (60 * ReserveFUEL[0]) / 12;
    }

    ReserveFUEL[4] = ReserveFUEL[0] + AlternateFUEL + (12 * 45) / 60;
    ReserveFUEL[7] = ReserveFUEL[4] + ReserveFUEL[5] + ReserveFUEL[6];
    ReserveFUEL[8] = FOB - ReserveFUEL[7];

    const existingPdfBytes = await fetch(OFP).then((res) => res.arrayBuffer());

    const fontBytes = await fetch(fuente).then((res) => res.arrayBuffer());
    const fontBytesbold = await fetch(fuentebold).then((res) =>
      res.arrayBuffer()
    );

    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    pdfDoc.registerFontkit(fontkit);
    const ubuntuFont = await pdfDoc.embedFont(fontBytes);
    const ubuntuFontbold = await pdfDoc.embedFont(fontBytesbold);

    // Get the first page of the document
    const pages = pdfDoc.getPages();

    const firstPage = pages[0];

    const paginas = [firstPage];

    var numerodepaginas = Math.ceil(mapValues.waypoints.length / 14);

    var p = 0;
    while (p < numerodepaginas - 1) {
      const [copia] = await pdfDoc.copyPages(pdfDoc, [0]);
      const copiada = pdfDoc.addPage(copia);
      paginas.push(copiada);
      p++;
    }

    const fontSize = 8;

    function getTextWidth(word) {
      return ubuntuFont.widthOfTextAtSize(word, fontSize);
    }

    function getRenglones(testLine) {
      const deleteThisWords = [
        "EL",
        "LA",
        "LOS",
        "LAS",
        "UN",
        "UNA",
        "UNOS",
        "UNAS",
        "A",
        "ANTE",
        "BAJO",
        "CON",
        "CONTRA",
        "DE",
        "DEL",
        "Y",
        "DESDE",
        "HACIA",
        "HASTA",
        "MEDIANTE",
        "CON",
        "SIN",
        "PARA",
        "POR",
        "SEGUN",
        "SEGÚN",
        "TRAS",
        "SO",
        "SOBRE",
        "VERSUS",
        "VIA",
        "VÍA",
      ];

      var renglones = [];

      var testArray = testLine.split(" ");

      var anchoCadena = getTextWidth(testLine);

      if (anchoCadena > waypointswidth && testArray.length > 1) {
        if (getTextWidth(testLine) > 2 * waypointswidth) {
          testArray = testArray.filter(
            (word) => !deleteThisWords.includes(word)
          );
        }
        if (
          getTextWidth(testArray.slice(1, testArray.length).join(" ")) >
          waypointswidth
        ) {
          //mandar palabras parriba
          renglones[0] = testArray.slice(0, 2).join(" ");
          renglones[1] = testArray.slice(2, testArray.length).join(" ");
        } else {
          renglones[0] = testArray[0];
          renglones[1] = testArray.slice(1, testArray.length).join(" ");
        }
      } else {
        renglones[0] = testLine;
      }

      return renglones;
    }

    const textHeight = ubuntuFont.heightAtSize(fontSize);

    const esquinawaypoints = [49, 331.5];
    const waypointsheight = (354 - 39) / 14;
    const waypointswidth = 117 - 49;

    const esquinaTC = [117, 331.5];
    const TCheight = (343 - 50) / 26;
    const TCwidth = 143 - 117;

    const esquinaVar = [143, 331.5];
    const Varheight = (343 - 50) / 26;
    const Varwidth = 165.5 - 143;

    const esquinaMC = [143, 331.5 - (343 - 50) / 26];
    const MCheight = (343 - 50) / 26;
    const MCwidth = 165.5 - 143;

    const esquinaAlt = [166, 331.5 - (343 - 50) / 26];
    const Restoheight = (343 - 50) / 13;
    const Altwidth = 192 - 166;

    const esquinaWindDirection = [192, 331.5 - (343 - 50) / 26];
    const WindDirectionwidth = 212 - 192;

    const esquinaWindSpeed = [212, 331.5 - (343 - 50) / 26];
    const WindSpeedwidth = 235.5 - 212;

    const esquinaMagneticHeading = [235.5, 331.5 - (343 - 50) / 26];
    const MagneticHeadingwidth = 262 - 235.5;

    const esquinaDistance = [262, 331.5 - (343 - 50) / 26];

    const esquinaTASGS = [288, 331.5 - (343 - 50) / 26];

    const esquinaEET = [314.5, 331.5 - (343 - 50) / 26];
    const Resto2width = 334.5 - 314;

    const esquinaETA = [334.5, 331.5 - (343 - 50) / 26];

    const esquinaATO = [355, 331.5 - (343 - 50) / 26];
    const ATOwidth = 377 - 355;

    const esquinaEST = [377, 331.5 - (343 - 50) / 26];

    const esquinaACT = [396, 331.5 - (343 - 50) / 26];

    const esquinaTotalDist = [262, 17];
    const TotalDistwidth = 288 - 262;
    const TotalsHeight = 39 - 17;

    const esquinaTotalEET = [314, 17];
    const TotalEETwidth = 334.5 - 314;

    const esquinaTotalEST = [377, 17];
    const TotalESTwidth = 396 - 377;

    const esquinaTIME = [525, 253];
    const heightTIME = (275 - 50) / 10;
    const widthTIME = 558.5 - 525;

    const esquinaFUEL = [558.5, 253];
    const widthFUEL = 597 - 558.5;

    const esquinaReserveTIME = [717.5, 253];
    const widthReserve = 38;

    const esquinaReserveFUEL = [756, 253];

    var i = 0;
    var w = 0;
    while (i < paginas.length) {
      var h = 0;
      while (w < CLBorDESC.length && h < 14) {
        let waypointNameArray = getRenglones(mapValues.waypoints[w].toString());

        for (j = 0; j < waypointNameArray.length; j++) {
          paginas[i].drawText(waypointNameArray[j], {
            x:
              esquinawaypoints[0] +
              waypointswidth / 2 -
              getTextWidth(waypointNameArray[j]) /
                2,
            y: waypointNameArray.length === 1 ? 
              (esquinawaypoints[1] -
              h * waypointsheight +
              waypointsheight / 2 -
              textHeight / 3)
              : j === 0 ?
              (esquinawaypoints[1] -
              h * waypointsheight +
              waypointsheight*(3/4) -
              textHeight / 3)
              :
              (esquinawaypoints[1] -
                h * waypointsheight +
                waypointsheight*(1/4) -
                textHeight / 3),
            font: ubuntuFont,
            size: fontSize,
            color: rgb(0, 0, 0),
          });
        }
        if (h === 13) {
          break;
        }

        paginas[i].drawText(parseDegrees(Math.round(mapValues.bearings[w])), {
          x:
            esquinaTC[0] +
            TCwidth / 2 -
            ubuntuFont.widthOfTextAtSize(
              parseDegrees(Math.round(mapValues.bearings[w])),
              fontSize
            ) /
              2,
          y: esquinaTC[1] - 2 * h * TCheight + TCheight / 2 - textHeight / 3,
          font: ubuntuFont,
          size: fontSize,
          color: rgb(0, 0, 0),
        });

        if (mapValues.magneticVariation[w] !== 0) {
          paginas[i].drawText(mapValues.magneticVariation[w].toString(), {
            x:
              esquinaVar[0] +
              Varwidth / 2 -
              ubuntuFont.widthOfTextAtSize(
                mapValues.magneticVariation[w].toString(),
                fontSize
              ) /
                2,
            y:
              esquinaVar[1] -
              2 * h * Varheight +
              Varheight / 2 -
              textHeight / 3,
            font: ubuntuFont,
            size: fontSize,
            color: rgb(0, 0, 0),
          });
        }

        paginas[i].drawText(parseDegrees(Math.round(MC[w])), {
          x:
            esquinaMC[0] +
            MCwidth / 2 -
            ubuntuFont.widthOfTextAtSize(
              parseDegrees(Math.round(MC[w])),
              fontSize
            ) /
              2,
          y: esquinaMC[1] - 2 * h * MCheight + MCheight / 2 - textHeight / 3,
          font: ubuntuFont,
          size: fontSize,
          color: rgb(0, 0, 0),
        });

        paginas[i].drawText(mapValues.altitudes[w].toString(), {
          x:
            esquinaAlt[0] +
            Altwidth / 2 -
            ubuntuFontbold.widthOfTextAtSize(
              mapValues.altitudes[w].toString(),
              fontSize
            ) /
              2,
          y: esquinaAlt[1] - h * Restoheight + Restoheight / 2 - textHeight / 3,
          font: ubuntuFontbold,
          size: fontSize,
          color: rgb(0, 0, 0),
        });

        if (mapValues.windSpeed[w] !== 0) {
          paginas[i].drawText(parseDegrees(mapValues.windDirection[w]), {
            x:
              esquinaWindDirection[0] +
              WindDirectionwidth / 2 -
              ubuntuFont.widthOfTextAtSize(
                parseDegrees(mapValues.windDirection[w]),
                fontSize
              ) /
                2,
            y:
              esquinaWindDirection[1] -
              h * Restoheight +
              Restoheight / 2 -
              textHeight / 3,
            font: ubuntuFont,
            size: fontSize,
            color: rgb(0, 0, 0),
          });

          paginas[i].drawText(mapValues.windSpeed[w].toString(), {
            x:
              esquinaWindSpeed[0] +
              WindSpeedwidth / 2 -
              ubuntuFont.widthOfTextAtSize(
                mapValues.windSpeed[w].toString(),
                fontSize
              ) /
                2,
            y:
              esquinaWindSpeed[1] -
              h * Restoheight +
              Restoheight / 2 -
              textHeight / 3,
            font: ubuntuFont,
            size: fontSize,
            color: rgb(0, 0, 0),
          });
        }

        paginas[i].drawText(parseDegrees(Math.round(MH[w])), {
          x:
            esquinaMagneticHeading[0] +
            MagneticHeadingwidth / 2 -
            ubuntuFontbold.widthOfTextAtSize(
              parseDegrees(Math.round(MH[w])),
              12
            ) /
              2,
          y:
            esquinaMagneticHeading[1] -
            h * Restoheight +
            Restoheight / 2 -
            ubuntuFontbold.heightAtSize(12) / 3,
          font: ubuntuFontbold,
          size: 12,
          color: rgb(0, 0, 0),
        });

        paginas[i].drawText(mapValues.distances[w].toFixed(1).toString(), {
          x:
            esquinaDistance[0] +
            MagneticHeadingwidth / 2 -
            ubuntuFont.widthOfTextAtSize(
              mapValues.distances[w].toFixed(1).toString(),
              fontSize
            ) /
              2,
          y:
            esquinaDistance[1] -
            h * Restoheight +
            Restoheight / 2 -
            textHeight / 3,
          font: ubuntuFont,
          size: fontSize,
          color: rgb(0, 0, 0),
        });

        paginas[i].drawText(Math.round(TAS[w]).toString(), {
          x:
            esquinaTASGS[0] +
            MagneticHeadingwidth / 2 -
            ubuntuFont.widthOfTextAtSize(
              Math.round(TAS[w]).toString(),
              fontSize
            ) /
              2,
          y:
            esquinaTASGS[1] -
            h * Restoheight +
            Restoheight / 2 -
            textHeight / 3 +
            Restoheight / 4,
          font: ubuntuFont,
          size: fontSize,
          color: rgb(0, 0, 0),
        });

        paginas[i].drawText(Math.round(GS[w]).toString(), {
          x:
            esquinaTASGS[0] +
            MagneticHeadingwidth / 2 -
            ubuntuFont.widthOfTextAtSize(
              Math.round(GS[w]).toString(),
              fontSize
            ) /
              2,
          y:
            esquinaTASGS[1] -
            h * Restoheight -
            textHeight / 3 +
            Restoheight / 4,
          font: ubuntuFont,
          size: fontSize,
          color: rgb(0, 0, 0),
        });

        paginas[i].drawText(Math.floor(EET[w]).toString().padStart(2, '0') + ":" + ((EET[w] - Math.floor(EET[w]))*60).toFixed(0).toString().padStart(2, '0'), {
          x:
            esquinaEET[0] +
            Resto2width / 2 -
            ubuntuFontbold.widthOfTextAtSize(
              Math.floor(EET[w]).toString().padStart(2, '0') + ":" + ((EET[w] - Math.floor(EET[w]))*60).toFixed(0).toString().padStart(2, '0'),
              fontSize
            ) /
              2,
          y: esquinaEET[1] - h * Restoheight + Restoheight / 2 - textHeight / 3,
          font: ubuntuFontbold,
          size: fontSize,
          color: rgb(0, 0, 0),
        });

        paginas[i].drawText(EST[w].toFixed(2).toString(), {
          x:
            esquinaEST[0] +
            Resto2width / 2 -
            ubuntuFont.widthOfTextAtSize(
              EST[w].toFixed(2).toString(),
              fontSize
            ) /
              2,
          y: esquinaEST[1] - h * Restoheight + Restoheight / 2 - textHeight / 3,
          font: ubuntuFont,
          size: fontSize,
          color: rgb(0, 0, 0),
        });
        w++;
        h++;
      }

      paginas[i].drawText(TotalDist[i].toFixed(1).toString(), {
        x:
          esquinaTotalDist[0] +
          TotalDistwidth / 2 -
          ubuntuFont.widthOfTextAtSize(
            TotalDist[i].toFixed(1).toString(),
            fontSize
          ) /
            2,
        y: esquinaTotalDist[1] + TotalsHeight / 2 - textHeight / 3,
        font: ubuntuFont,
        size: fontSize,
        color: rgb(0, 0, 0),
      });

      paginas[i].drawText(Math.floor(TotalEET[i]).toString().padStart(2, '0') + ":" + ((TotalEET[i] - Math.floor(TotalEET[i]))*60).toFixed(0).toString().padStart(2, '0'), {
        x:
          esquinaTotalEET[0] +
          TotalEETwidth / 2 -
          ubuntuFont.widthOfTextAtSize(
            Math.floor(TotalEET[i]).toString().padStart(2, '0') + ":" + ((TotalEET[i] - Math.floor(TotalEET[i]))*60).toFixed(0).toString().padStart(2, '0'),
            fontSize
          ) /
            2,
        y: esquinaTotalEET[1] + TotalsHeight / 2 - textHeight / 3,
        font: ubuntuFont,
        size: fontSize,
        color: rgb(0, 0, 0),
      });

      paginas[i].drawText(TotalEST[i].toFixed(2).toString(), {
        x:
          esquinaTotalEST[0] +
          TotalESTwidth / 2 -
          ubuntuFont.widthOfTextAtSize(
            TotalEST[i].toFixed(2).toString(),
            fontSize
          ) /
            2,
        y: esquinaTotalEST[1] + TotalsHeight / 2 - textHeight / 3,
        font: ubuntuFont,
        size: fontSize,
        color: rgb(0, 0, 0),
      });

      paginas[i].drawText("MODEL DA-40", {
        x: 337.5,
        y: 489 + (500 - 489) / 2 - textHeight / 3,
        font: ubuntuFontbold,
        size: 7,
        color: rgb(0, 0, 0),
      });

      paginas[i].drawText("DATE " + departureTime.slice(0, 10) + " " + departureTime.slice(11, 16), {
        x: 337.5,
        y: 500 + (511 - 500) / 2 - textHeight / 3,
        font: ubuntuFontbold,
        size: 7,
        color: rgb(0, 0, 0),
      });

      paginas[i].drawText("REG EC-" + modelData.matricula, {
        x: 51,
        y: 489 + (500 - 489) / 2 - textHeight / 3,
        font: ubuntuFontbold,
        size: 7,
        color: rgb(0, 0, 0),
      });

      i++;
    }

    paginas[i - 1].drawText(mapValues.waypoints[w].toString(), {
      x:
        esquinawaypoints[0] +
        waypointswidth / 2 -
        ubuntuFont.widthOfTextAtSize(
          mapValues.waypoints[w].toString(),
          fontSize
        ) /
          2,
      y:
        esquinawaypoints[1] -
        h * waypointsheight +
        waypointsheight / 2 -
        textHeight / 3,
      font: ubuntuFont,
      size: fontSize,
      color: rgb(0, 0, 0),
    });

    var i = 0;
    while (i < TotalTIME.length) {
      paginas[numerodepaginas - 1].drawText(
        TotalTIME[i].toFixed(2).toString(),
        {
          x:
            esquinaTIME[0] +
            widthTIME / 2 -
            ubuntuFont.widthOfTextAtSize(
              TotalTIME[i].toFixed(2).toString(),
              fontSize
            ) /
              2,
          y: esquinaTIME[1] + heightTIME / 2 - textHeight / 3 - i * heightTIME,
          font: ubuntuFont,
          size: fontSize,
          color: rgb(0, 0, 0),
        }
      );

      paginas[numerodepaginas - 1].drawText(
        TotalFUEL[i].toFixed(2).toString(),
        {
          x:
            esquinaFUEL[0] +
            widthFUEL / 2 -
            ubuntuFont.widthOfTextAtSize(
              TotalFUEL[i].toFixed(2).toString(),
              fontSize
            ) /
              2,
          y: esquinaFUEL[1] + heightTIME / 2 - textHeight / 3 - i * heightTIME,
          font: ubuntuFont,
          size: fontSize,
          color: rgb(0, 0, 0),
        }
      );

      i++;
    }

    paginas[numerodepaginas - 1].drawText(
      TotalEST[TotalEST.length - 1].toFixed(2).toString(),
      {
        x:
          esquinaFUEL[0] +
          widthFUEL / 2 -
          ubuntuFont.widthOfTextAtSize(
            TotalEST[TotalEST.length - 1].toFixed(2).toString(),
            fontSize
          ) /
            2,
        y: esquinaFUEL[1] + heightTIME / 2 - textHeight / 3 - 4 * heightTIME,
        font: ubuntuFont,
        size: fontSize,
        color: rgb(0, 0, 0),
      }
    );

    for (i = 0; i < 3; i++) {
      paginas[numerodepaginas - 1].drawText(
        ReserveTIME[i].toFixed(2).toString(),
        {
          x:
            esquinaReserveTIME[0] +
            widthReserve / 2 -
            ubuntuFont.widthOfTextAtSize(
              ReserveTIME[i].toFixed(2).toString(),
              fontSize
            ) /
              2,
          y:
            esquinaReserveTIME[1] +
            heightTIME / 2 -
            textHeight / 3 -
            i * heightTIME,
          font: ubuntuFont,
          size: fontSize,
          color: rgb(0, 0, 0),
        }
      );

      paginas[numerodepaginas - 1].drawText(
        ReserveFUEL[i].toFixed(2).toString(),
        {
          x:
            esquinaReserveFUEL[0] +
            widthReserve / 2 -
            ubuntuFont.widthOfTextAtSize(
              ReserveFUEL[i].toFixed(2).toString(),
              fontSize
            ) /
              2,
          y:
            esquinaReserveFUEL[1] +
            heightTIME / 2 -
            textHeight / 3 -
            i * heightTIME,
          font: ubuntuFont,
          size: fontSize,
          color: rgb(0, 0, 0),
        }
      );
    }

    for (i = 4; i < 10; i++) {
      paginas[numerodepaginas - 1].drawText(
        ReserveFUEL[i].toFixed(2).toString(),
        {
          x:
            esquinaReserveFUEL[0] +
            widthReserve / 2 -
            ubuntuFont.widthOfTextAtSize(
              ReserveFUEL[i].toFixed(2).toString(),
              fontSize
            ) /
              2,
          y:
            esquinaReserveFUEL[1] +
            heightTIME / 2 -
            textHeight / 3 -
            i * heightTIME,
          font: ubuntuFont,
          size: fontSize,
          color: rgb(0, 0, 0),
        }
      );
    }

    const pdfBytes = await pdfDoc.save();
    if (PDFtoDownload === "boton-OFP") {
      // Trigger the browser to download the PDF document
      download(pdfBytes, "OFP DA40.pdf", "application/pdf");
    }
    const existingPdfBytesFlightProfile = await fetch(VerticalProfile).then((res) => res.arrayBuffer());

    const pdfDocFlightProfile = await PDFDocument.load(existingPdfBytesFlightProfile);

    // Get the first page of the document
    const pagesFlightProfile = pdfDocFlightProfile.getPages();

    pdfDocFlightProfile.registerFontkit(fontkit);
    const ubuntuFont2 = await pdfDocFlightProfile.embedFont(fontBytes);
    const ubuntuFontbold2 = await pdfDocFlightProfile.embedFont(fontBytesbold);

    function trocearArray(array, chunkSize) {
      const result = [];
      for (let i = 0; i < array.length; i += chunkSize) {
        const chunk = array.slice(i, i + chunkSize);
        result.push(chunk);
      }
      return result;
    }

    function trocearArrayWithOverlap(array, chunkSize) {
      const result = [];
      for (let i = 0; i < array.length - 1; i += chunkSize - 1) {
        const chunk = array.slice(i, i + chunkSize);
        result.push(chunk);
      }
      return result;
    }

    function lenghtOfSubArrays(array) {
      const result = [];
      for (let i = 0; i < array.length; i++) {
        const longitud = array[i].length;
        result.push(longitud);
      }
      return result;
    }

    function sumChunks(arr, chunkSize) {
      const result = [];
      for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        const chunkSum = chunk.reduce((acc, num) => acc + num, 0);
        result.push(chunkSum);
      }
      return result;
    }

    function findDifference(arr) {
      const max = Math.max(...arr);
      const min = Math.min(...arr);

      return max - min;
    }

    var altitudesWithDepElev = mapValues.altitudes
    altitudesWithDepElev.unshift(DEPElev)

    var distancesFlightProfile = trocearArray(mapValues.distances, 15)

    var timesFlightProfile = trocearArray(EET, 15)

    var consumptionsFlightProfile = trocearArray(EST, 15)

    var waypointsFlightProfile = trocearArrayWithOverlap(mapValues.waypoints, 16)

    var altitudesFlightProfile = trocearArrayWithOverlap(altitudesWithDepElev, 16)

    const firstPage2 = pagesFlightProfile[0];

    const paginas2 = [firstPage2];

    var numerodepaginas2 = Math.ceil(mapValues.waypoints.length / 16);

    for (p = 0; p < numerodepaginas2 - 1; p++) {
      const [copia] = await pdfDocFlightProfile.copyPages(pdfDocFlightProfile, [0]);
      const copiada = pdfDocFlightProfile.addPage(copia);
      paginas2.push(copiada);
    }

    var anchoCelda = []
    for (i = 0; i < distancesFlightProfile.length; i++) {
      var value = (816 - 25) / lenghtOfSubArrays(distancesFlightProfile)[i]
      anchoCelda.push(value)
    }

    var TotalDistancia = sumChunks(mapValues.distances, 15)

    var TotalTiempos = sumChunks(EET, 15)

    var TotalConsumos = sumChunks(EST, 15)

    var altoCelda = 195 - 166
    var anchoCeldaTotales = 529 - 421
    var altoCeldaTotales = 62 - 42
    const esquinaDistanciaVertProf = [25, 166]
    const esquinaTiempoVertProf = [25, 135]
    const esquinaConsumoVertProf = [25, 102]

    const esquinaTotalDistanciaVertProf = [421, 66]
    const esquinaTotalTiempoVertProf = [421, 42]
    const esquinaTotalConsumoVertProf = [421, 18]

    var etiquetasAltitudes = []
    var abcisasInicialEtiquetasAltitudes = []
    var abcisasFinalEtiquetasAltitudes = []
    var ordenadasEtiquetasAltitudes = []

    altitudesFlightProfile.forEach((subArray, index) => {
      let subArrayInicio = [];
      let subArrayFinal = [];
      let subArrayOrdenadasAltitudes = [];
      let subArrayEtiquetas = [];
      let indexito = index;

      subArray.forEach((value, index) => {
        if (value !== subArray[index - 1] && value === subArray[index + 1]) {
          subArrayInicio.push(25 + index * anchoCelda[indexito]);
          subArrayEtiquetas.push(value);
          subArrayOrdenadasAltitudes.push(195 + (value - Math.min(...altitudesWithDepElev)) * (349 - 195) / (findDifference(altitudesWithDepElev)))
        }

        if (value === subArray[index - 1] && value !== subArray[index + 1]) {
          subArrayFinal.push(25 + index * anchoCelda[indexito]);
        }
      });

      abcisasInicialEtiquetasAltitudes = abcisasInicialEtiquetasAltitudes.concat(Array(subArrayInicio));
      abcisasFinalEtiquetasAltitudes = abcisasFinalEtiquetasAltitudes.concat(Array(subArrayFinal));
      ordenadasEtiquetasAltitudes = ordenadasEtiquetasAltitudes.concat(Array(subArrayOrdenadasAltitudes))
      etiquetasAltitudes = etiquetasAltitudes.concat(Array(subArrayEtiquetas))
    });

    let sumOfTimes = 0;

    for (i = 0; i < anchoCelda.length; i++) {
      for (j = 0; j < distancesFlightProfile[i].length; j++) {
        paginas2[i].drawText(distancesFlightProfile[i][j].toFixed(1).toString(), {
          x:
            (esquinaDistanciaVertProf[0]
              +
              anchoCelda[i] / 2
              -
              ubuntuFontbold2.widthOfTextAtSize(
                distancesFlightProfile[i][j].toFixed(1).toString(),
                18
              ) /
              2) + (j * anchoCelda[i])
          ,
          y: esquinaDistanciaVertProf[1] + altoCelda / 2 - altoCelda / 4,
          font: ubuntuFontbold2,
          size: 18,
          color: rgb(1, 1, 1),
        });

        paginas2[i].drawText(Math.floor(timesFlightProfile[i][j]).toString().padStart(2, "0") +
          ":" +
          ((timesFlightProfile[i][j] - Math.floor(timesFlightProfile[i][j])) * 60)
            .toFixed(0)
            .toString()
            .padStart(2, "0"), {
          x:
            (esquinaTiempoVertProf[0]
              +
              anchoCelda[i] / 2
              -
              ubuntuFontbold2.widthOfTextAtSize(
                Math.floor(timesFlightProfile[i][j]).toString().padStart(2, "0") +
                ":" +
                ((timesFlightProfile[i][j] - Math.floor(timesFlightProfile[i][j])) * 60)
                  .toFixed(0)
                  .toString()
                  .padStart(2, "0"),
                18
              ) /
              2) + (j * anchoCelda[i])
          ,
          y: esquinaTiempoVertProf[1] + altoCelda / 2 - altoCelda / 4,
          font: ubuntuFontbold2,
          size: 18,
          color: rgb(1, 1, 1),
        });

        sumOfTimes += timesFlightProfile[i][j]
        
        if (sumOfTimes > 30) {
          paginas2[i].drawText(
            "CHANGE FUEL TANK SELECTION",
            {
              x: 25 + (j+0.5)*anchoCelda[i] + ubuntuFont.heightAtSize(9) / 3,
              y: 195 + (349-195)/2 - ubuntuFontbold2.widthOfTextAtSize(
                "CHANGE FUEL TANK SELECTION",
                9
              ) /
                2,
              font: ubuntuFontbold2,
              size: 9,
              color: rgb(0,0,0),
              rotate: degrees(90)
            }
          )

          sumOfTimes = 0
        }

        paginas2[i].drawText(consumptionsFlightProfile[i][j].toFixed(2).toString(), {
          x:
            (esquinaConsumoVertProf[0]
              +
              anchoCelda[i] / 2
              -
              ubuntuFontbold2.widthOfTextAtSize(
                consumptionsFlightProfile[i][j].toFixed(2).toString(),
                18
              ) /
              2) + (j * anchoCelda[i])
          ,
          y: esquinaConsumoVertProf[1] + altoCelda / 2 - altoCelda / 4,
          font: ubuntuFontbold2,
          size: 18,
          color: rgb(1, 1, 1),
        });

        paginas2[i].drawLine({
          start: { x: 25 + j * anchoCelda[i], y: 102 },
          end: { x: 25 + j * anchoCelda[i], y: 195 },
          thickness: 1,
          color: rgb(1, 1, 1),
        })

        paginas2[i].drawLine({
          start: { x: 25 + j * anchoCelda[i], y: 195 },
          end: { x: 25 + j * anchoCelda[i], y: 349 },
          thickness: 1,
          color: rgb(0.956862745, 0.635294118, 0.380392157),
        })

        paginas2[i].drawLine({
          start: { x: 25 + j * anchoCelda[i], y: 195 + (altitudesFlightProfile[i][j] - Math.min(...altitudesWithDepElev)) * (349 - 195) / (findDifference(altitudesWithDepElev)) },
          end: { x: 25 + (j + 1) * anchoCelda[i], y: 195 + (altitudesFlightProfile[i][j + 1] - Math.min(...altitudesWithDepElev)) * (349 - 195) / (findDifference(altitudesWithDepElev)) },
          thickness: 2,
          color: rgb(0.956862745, 0.635294118, 0.380392157),
        })
      }

      for (j = 0; j < waypointsFlightProfile[i].length; j++) {
        let waypointNameArray = getRenglones(waypointsFlightProfile[i][j].toString());

        for (k = 0; k < waypointNameArray.length; k++) {

          paginas2[i].drawText(waypointNameArray[k], {
            x: waypointNameArray.length === 1 ?
              25 + j * anchoCelda[i] + ubuntuFont.heightAtSize(18) / 3
              : k === 0 ?
                (25 + j * anchoCelda[i] - ubuntuFont.heightAtSize(18) / 4)
                :
                (25 + j * anchoCelda[i] + ubuntuFont.heightAtSize(18) * 0.8)
            ,
            y: 355,
            font: ubuntuFontbold2,
            size: 18,
            color: rgb(0, 0, 0),
            rotate: degrees(90),
          })
        }

      }

      paginas2[i].drawText(TotalDistancia[i].toFixed(1).toString(), {
        x:
          (esquinaTotalDistanciaVertProf[0]
            +
            anchoCeldaTotales / 2
            -
            ubuntuFontbold2.widthOfTextAtSize(
              TotalDistancia[0].toFixed(1).toString(),
              18
            ) /
            2
          )
        ,
        y: esquinaTotalDistanciaVertProf[1] + altoCeldaTotales / 2 - altoCeldaTotales / 4,
        font: ubuntuFontbold2,
        size: 18,
        color: rgb(1, 1, 1),
      });

      paginas2[i].drawText(TotalTiempos[i].toFixed(1).toString(), {
        x:
          (esquinaTotalTiempoVertProf[0]
            +
            anchoCeldaTotales / 2
            -
            ubuntuFontbold2.widthOfTextAtSize(
              TotalTiempos[0].toFixed(1).toString(),
              18
            ) /
            2
          )
        ,
        y: esquinaTotalTiempoVertProf[1] + altoCeldaTotales / 2 - altoCeldaTotales / 4,
        font: ubuntuFontbold2,
        size: 18,
        color: rgb(1, 1, 1),
      });

      paginas2[i].drawText(TotalConsumos[i].toFixed(1).toString(), {
        x:
          (esquinaTotalConsumoVertProf[0]
            +
            anchoCeldaTotales / 2
            -
            ubuntuFontbold2.widthOfTextAtSize(
              TotalConsumos[0].toFixed(1).toString(),
              18
            ) /
            2
          )
        ,
        y: esquinaTotalConsumoVertProf[1] + altoCeldaTotales / 2 - altoCeldaTotales / 4,
        font: ubuntuFontbold2,
        size: 18,
        color: rgb(1, 1, 1),
      });

      for (j = 0; j < etiquetasAltitudes[i].length; j++) {

        paginas2[i].drawText(etiquetasAltitudes[i][j] + " ft".toString(), {
          x:
            abcisasInicialEtiquetasAltitudes[i][j]
            +
            (abcisasFinalEtiquetasAltitudes[i][j] - abcisasInicialEtiquetasAltitudes[i][j]) / 2
            -
            ubuntuFontbold2.widthOfTextAtSize(
              etiquetasAltitudes[i][j].toString() + " ft",
              14
            ) /
            2,
          y: ordenadasEtiquetasAltitudes[i][j] > 348 ?
            ordenadasEtiquetasAltitudes[i][j] - 13
            :
            ordenadasEtiquetasAltitudes[i][j] + 5,
          font: ubuntuFontbold2,
          size: 14,
          color: rgb(0, 0, 0),
        })
      }

      var tablillaFlightProfile = [
        "FLIGHT PROFILE",
        "FROM " +
          mapValues.waypoints[0].toString() +
          " TO " +
          mapValues.waypoints.slice(-1).toString(),
        "MODEL: " + modelData.tipo,
        "REGISTRATION: EC-" + modelData.matricula,
        "DOF: " +
          departureTime.slice(0, 10) +
          " " +
          departureTime.slice(11, 16),
      ];

      for (j = 0; j < tablillaFlightProfile.length; j++) {
        paginas2[i].drawText(tablillaFlightProfile[j], {
          x:
            j === 0
              ? 25 +
                ubuntuFontbold2.widthOfTextAtSize(
                  tablillaFlightProfile[j],
                  14
                ) /
                  2
              : 30,
          y: 84 - j * 19,
          font: j === 0
          ?
          ubuntuFontbold2:
          ubuntuFont2,
          size: 14,
          color: rgb(0, 0, 0),
        });
      }

    }

    const pdfBytes2 = await pdfDocFlightProfile.save();
    if (PDFtoDownload === "boton-FlightProf") {
      // Trigger the browser to download the PDF document
      download(pdfBytes2, "Flight Profile.pdf", "application/pdf");
    }

  }

  return (
    <div>
      <button
        className="boton-generar-pdf"
        id="boton-OFP"
        onClick={(e) => {
          handleClick(e);
          setDisableMap(true);
        }}
        disabled={isSending}

      >
        Download OFP
      </button>
      <button
        className="boton-generar-pdf"
        id="boton-FlightProf"
        onClick={(e) => {
          handleClick(e);
          setDisableMap(true);
        }}
        disabled={isSending}
      >
        Download Flight Profile
      </button>
      <Popup open={disableMap}>
        {(close) => {
          return (
            <div className="modal">
              <div className="modal-content">
                <div className="header"> DISCLAIMER </div>
                <p>CHARTS NOT INTENDED FOR OPERATIONAL USE</p>
                <p>USE AT OWN RISK</p>
                <p>You have to know how to get to these results by hand</p>
                <p>YOU MAY BE ASKED ABOUT IT IN YOUR PRE-FLIGHT BRIEFING</p>

                <button
                  className="modal-close"
                  onClick={(e) => {
                    e.preventDefault();
                    setDisableMap(false);
                    close();
                  }}
                >
                  Okay
                </button>
              </div>
            </div>
          );
        }}
      </Popup>
    </div>

  );
}
