import { React, useEffect, useState } from "react";
import Popup from "reactjs-popup";
import { Link } from "react-router-dom";
import OFPPA28 from "./OFPPA28";
import OFPC172 from "./OFPC172";
import OFPDA40 from "./OFPDA40";
import OFPPMENTOR from "./OFPPMENTOR";
import OFPPA34 from "./OFPPA34";
import OFPP2006T from "./OFPP2006T";
import { ReactComponent as LogoSvg } from "./PDFTemplates/logo.svg";

import rutas from "./Rutas.js";

export default function FlightPlan({
  modelData,
  setModelData,
  data,
  handleChange2,
}) {
  const [disableMap, setDisableMap] = useState(false);
  useEffect(() => console.log(disableMap), [disableMap]);

  const [mapValues, setMapValues] = useState({
    waypoints: [],
    distances: [],
    bearings: [],
    altitudes: [],
    windDirection: [],
    windSpeed: [],
    magneticVariation: [],
    temperature: [],
    coordinates: [],
  });
  const [routeParameters, setRouteParameters] = useState({
    departureElevation: 0,
    arrivalElevation: 0,
    alternativeElevation: 0,
    departureQNH: 0,
    cruiseRpm: 0,
    routeTemperature: 0,
    routeMagneticVariation: 0,
    routeWindDirection: 0,
    routeWindSpeed: 0,
  });

  const [currentRoute, setCurrentRoute] = useState("");
  const [departureTime, setDepartureTime] = useState("");

  const [file, setFile] = useState();
  const [isUpload, setIsUpload] = useState(false);
  const fileReader = new FileReader();

  const handleUpload = (e) => {
    setFile(e.target.files[0]);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (file) {
      fileReader.onload = function (event) {
        const output = JSON.parse(event.target.result);
        output[0].coordinates.splice(-1);
        setMapValues(output[0]);
        setRouteParameters(output[1]);
        setIsUpload((prevState) => !prevState);
      };
      fileReader.readAsText(file);
    }
  };

  function handleKeyDown(e) {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault();
    }
  }

  function handleChange(e) {
    if (e.target.name === "ruta") {
      setCurrentRoute(e.target.value);
    }
    if (e.target.name === "departure-time") {
      setDepartureTime(e.target.value);
    }
    if (e.target.name.includes("waypoint")) {
      setMapValues((prevState) => {
        const newState = { ...prevState };
        newState.waypoints[e.target.id] = e.target.value;
        return newState;
      });
    } else if (e.target.name.includes("distance")) {
      setMapValues((prevState) => {
        const newState = { ...prevState };
        newState.distances[e.target.id] = e.target.valueAsNumber;
        return newState;
      });
    } else if (e.target.name.includes("bearing")) {
      setMapValues((prevState) => {
        const newState = { ...prevState };
        newState.bearings[e.target.id] =
          e.nativeEvent.data === "0" && !e.target.valueAsNumber
            ? "0"
            : e.target.valueAsNumber;
        return newState;
      });
    } else if (e.target.name.includes("altitude")) {
      setMapValues((prevState) => {
        const newState = { ...prevState };
        newState.altitudes[e.target.id] =
          e.nativeEvent.data === "0" && !e.target.valueAsNumber
            ? "0"
            : e.target.valueAsNumber;

        return newState;
      });
    } else if (e.target.name.includes("windDirection")) {
      setMapValues((prevState) => {
        const newState = { ...prevState };
        newState.windDirection[e.target.id] =
          e.nativeEvent.data === "0" && !e.target.valueAsNumber
            ? "0"
            : e.target.valueAsNumber;
        return newState;
      });
    } else if (e.target.name.includes("windSpeed")) {
      setMapValues((prevState) => {
        const newState = { ...prevState };
        newState.windSpeed[e.target.id] =
          e.nativeEvent.data === "0" && !e.target.valueAsNumber
            ? "0"
            : e.target.valueAsNumber;

        return newState;
      });
    } else if (e.target.name.includes("magneticVariation")) {
      setMapValues((prevState) => {
        const newState = { ...prevState };
        newState.magneticVariation[e.target.id] =
          e.nativeEvent.data === "0" && !e.target.valueAsNumber
            ? "0"
            : e.target.valueAsNumber;
        return newState;
      });
    } else if (e.target.name.includes("temperature")) {
      setMapValues((prevState) => {
        const newState = { ...prevState };
        newState.temperature[e.target.id] =
          e.nativeEvent.data === "0" && !e.target.valueAsNumber
            ? "0"
            : e.target.valueAsNumber;
        return newState;
      });
    } else {
      setRouteParameters((prevState) => {
        const newState = { ...prevState };
        newState[e.target.name] =
          e.nativeEvent.data === "0" && !e.target.valueAsNumber
            ? "0"
            : e.target.valueAsNumber;
        return newState;
      });
    }
  }

  const modelos = Object.keys(data);

  useEffect(() => {
    if (modelData.modelo && modelData.matricula) {
      setModelData((prevState) => ({
        ...prevState,
        bag1: data[modelData.modelo].MBDATA.bag1,
        bag2: data[modelData.modelo].MBDATA.bag2,
        bag3: data[modelData.modelo].MBDATA.bag3,
        bag4: data[modelData.modelo].MBDATA.bag4,
        fob: data[modelData.modelo].MBDATA.fob,
        fpax: data[modelData.modelo].MBDATA.fpax,
        oilna: data[modelData.modelo].MBDATA.oilna,
        rpax1: data[modelData.modelo].MBDATA.rpax1,
        rpax2: data[modelData.modelo].MBDATA.rpax2,
        rpax3: data[modelData.modelo].MBDATA.rpax3,
        cruiseSpeed: data[modelData.modelo].MBDATA.cruiseSpeed,
        cg: data[modelData.modelo].MATRICULAS[modelData.matricula].CG,
        tipo: data[modelData.modelo].MATRICULAS[modelData.matricula].TIPO,
        momento: data[modelData.modelo].MATRICULAS[modelData.matricula].MOMENTO,
        peso: data[modelData.modelo].MATRICULAS[modelData.matricula].PESO,
      }));
    }
  }, [modelData.matricula, setModelData, modelData.modelo, data]);

  useEffect(() => {
    const map = new window.L.Map("map", { fullscreenControl: true }).setView(
      [40.37061887752958, -3.7841726792157018],
      11
    );

    window.L.tileLayer("https://{s}.tile.osm.org/{z}/{x}/{y}.png",
    {
      attribution:
        '&copy; <a href="https://www.enaire.es/aviso_legal">ENAIRE</a> contributors',
    }).addTo(map);

    let options = {
      position: "topleft",
      unit: "nauticalmiles",
      showClearControl: false,
      circleMarker: {
        color: "red",
        radius: 2,
        draggable: true,
      },
      measureControlClasses: [],
      unitControlLabel: {
        nauticalmiles: "NM",
      },
      fixedLine: {
        color: "red",
        weight: 3,
      },
      tempLine: {
        color: "red",
        weight: 2,
      },
      arrow: {
        color: "red",
      },
      lengthUnit: {
        factor: 0.539956803,
        display: "NM",
        decimal: 1,
        label: "Distance:",
        visibility: "visible",
      },
      angleUnit: {
        display: "&deg;",
        decimal: 0,
        factor: null,
        label: "Track:",
      },
      clearMeasurementsOnStop: false,
    };

    let polylineMeasure = window.L.control.polylineMeasure(options);

    polylineMeasure.addTo(map);

    var IFR = window.L.tileLayer(
      "https://plandevueloaep-9ac5a.web.app/static/media/TilesIFR/{z}/{x}/{y}.png",
      {
        minZoom: 8,
        maxZoom: 11,
        tms: true,
      }
    );

    var VFR = window.L.tileLayer(
      "https://plandevueloaep-9ac5a.web.app/static/media/TilesVFR/{z}/{x}/{y}.jpg",
      {
        minZoom: 9,
        maxZoom: 12,
        tms: true,
      }
    ).addTo(map);

    var baseMaps = {
      VFR: VFR,
      IFR: IFR,
    };

    window.L.control.layers(baseMaps).addTo(map);

    map.on("click", function (e) {});

    const calcAngle = function (p1, p2, direction) {
      let brng = 0;
      let lat1 = (p1.lat / 180) * Math.PI;
      let lat2 = (p2.lat / 180) * Math.PI;
      let lng1 = (p1.lng / 180) * Math.PI;
      var lng2 = (p2.lng / 180) * Math.PI;
      var y = Math.sin(lng2 - lng1) * Math.cos(lat2);
      var x =
        Math.cos(lat1) * Math.sin(lat2) -
        Math.sin(lat1) * Math.cos(lat2) * Math.cos(lng2 - lng1);
      if (direction === "inbound") {
        brng = ((Math.atan2(y, x) * 180) / Math.PI + 180).toFixed(0);
      } else {
        brng = ((Math.atan2(y, x) * 180) / Math.PI + 360).toFixed(0);
      }
      return brng % 360;
    };

    function debugevent(e) {
      var coords = e.circleCoords ? e.circleCoords : e;
      var lastInd = coords.length - 1;
      if (lastInd > 0) {
        var trackArray = coords
          .map((point, index) =>
            index === 0 ? null : calcAngle(coords[index - 1], point, "outbound")
          )
          .filter((element) => element !== null);

        var distanceArray = coords
          .map((point, index) =>
            index === 0
              ? null
              : Number((coords[index - 1].distanceTo(point) / 1852).toFixed(2))
          )
          .filter((element) => element !== null);

        setMapValues((prevState) => {
          return {
            ...prevState,
            bearings: trackArray,
            distances: distanceArray,
            coordinates: Object.keys(coords).map((point) => coords[point]),
          };
        });
      }
    }

    let selectedRouteCoordinates = currentRoute
      ? Object.keys(rutas[currentRoute]).map(
          (point) => rutas[currentRoute][point]["COORDINATES"]
        )
      : [];
    let selectedRouteAltitudes = currentRoute
      ? Object.keys(rutas[currentRoute]).map(
          (point) => rutas[currentRoute][point]["ALTITUDE"]
        )
      : [];
    let selectedRouteWaypointNames = currentRoute
      ? Object.keys(rutas[currentRoute]).map(
          (point) => rutas[currentRoute][point]["NAME"]
        )
      : [];

    if (file) {
      polylineMeasure.seed([mapValues.coordinates]);
      debugevent(polylineMeasure._arrPolylines[0].circleCoords);
      let allLatitudes = Object.keys(mapValues.coordinates).map(
        (point) => mapValues.coordinates[point]["lat"]
      );
      let allLongitudes = Object.keys(mapValues.coordinates).map(
        (point) => mapValues.coordinates[point]["lng"]
      );

      let allLatitudesIntheMiddle = [];
      let allLongitudesIntheMiddle = [];

      for (let i = 0; i < allLatitudes.length - 1; i++) {
        allLatitudesIntheMiddle.push(
          (allLatitudes[i] + allLatitudes[i + 1]) / 2
        );
        allLongitudesIntheMiddle.push(
          (allLongitudes[i] + allLongitudes[i + 1]) / 2
        );
      }

      map.flyToBounds(
        [
          [Math.max(...allLatitudes), Math.min(...allLongitudes)],
          [Math.min(...allLatitudes), Math.max(...allLongitudes)],
        ],
        { animate: false }
      );
    }

    if (currentRoute) {
      let allLatitudes = Object.keys(selectedRouteCoordinates).map(
        (point) => selectedRouteCoordinates[point]["lat"]
      );
      let allLongitudes = Object.keys(selectedRouteCoordinates).map(
        (point) => selectedRouteCoordinates[point]["lng"]
      );

      let allLatitudesIntheMiddle = [];
      let allLongitudesIntheMiddle = [];

      for (let i = 0; i < allLatitudes.length - 1; i++) {
        allLatitudesIntheMiddle.push(
          (allLatitudes[i] + allLatitudes[i + 1]) / 2
        );
        allLongitudesIntheMiddle.push(
          (allLongitudes[i] + allLongitudes[i + 1]) / 2
        );
      }

      polylineMeasure.seed([selectedRouteCoordinates]);
      debugevent(polylineMeasure._arrPolylines[0].circleCoords);

      routeParameters.departureElevation = selectedRouteAltitudes[0];
      routeParameters.arrivalElevation = selectedRouteAltitudes.slice(-1);
      mapValues.altitudes = selectedRouteAltitudes.slice(1);
      mapValues.waypoints = selectedRouteWaypointNames;

      map.flyToBounds(
        [
          [Math.max(...allLatitudes), Math.min(...allLongitudes)],
          [Math.min(...allLatitudes), Math.max(...allLongitudes)],
        ],
        { animate: false }
      );
    } else if (!file) {
      setMapValues({
        waypoints: [],
        distances: [],
        bearings: [],
        altitudes: [],
        windDirection: [],
        windSpeed: [],
        magneticVariation: [],
        temperature: [],
      });
      setRouteParameters((prevState) => {
        return { ...prevState, departureElevation: 0, arrivalElevation: 0 };
      });
    }

    map.on("polylinemeasure:change", debugevent);

    return () => {
      map.remove();
    };
  }, [currentRoute, isUpload]);

  const exportData = () => {
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify([mapValues, routeParameters])
    )}`;
    const link = document.createElement("a");
    link.href = jsonString;
    link.download = mapValues.waypoints[0] + " to " + mapValues.waypoints.slice(-1) +".json";

    link.click();
  };

  return (
    <div className="main-container">
      <div className="nav-container">
        <Link to="/">
          <button className="nav-logo">
            <LogoSvg />
          </button>
        </Link>
        <div className="nav-buttons">
          <Link to="/flightplan">
            <button className="button active-page-btn">Flight Plan</button>
          </Link>
          <Link to="/massandbalance">
            <button className="button btn-mb">Mass & Balance</button>
          </Link>
          <Link to="/meteo">
            <button className="button">Meteo</button>
          </Link>
        </div>
        <a
          href="https://www.paypal.com/donate/?hosted_button_id=RAZU6S7ZT5DS2"
          target="_blank"
          rel="noreferrer"
        >
          <button className="boton-generar-pdf">Donate</button>
        </a>
      </div>
      <div className="select-model-container">
        <label htmlFor="modelo">Select a model</label>
        <select
          id="modelo"
          name="modelo"
          onChange={handleChange2}
          value={modelData.modelo}
        >
          <option value="">---</option>
          {modelos.map((val) => (
            <option value={val} key={val}>
              {val}
            </option>
          ))}
        </select>
        {modelData.modelo && (
          <div>
            <label htmlFor="matricula">Select a registration number</label>
            <select
              id="matricula"
              name="matricula"
              onChange={handleChange2}
              value={modelData.matricula}
            >
              <option value="">---</option>
              {Object.keys(
                data[modelos[modelos.indexOf(modelData.modelo)]]["MATRICULAS"]
              ).map((val) => (
                <option value={val} key={val}>
                  {val}
                </option>
              ))}
            </select>
          </div>
        )}
        {modelData.modelo && (
          <div>
            <label htmlFor="ruta">Do you want a pre-planned route?</label>
            <select
              id="ruta"
              name="ruta"
              onChange={handleChange}
              value={currentRoute}
            >
              <option value="">---</option>
              {Object.keys(rutas).map((val) => (
                <option value={val} key={val}>
                  {val}
                </option>
              ))}
            </select>
          </div>
        )}
        {modelData.modelo && (
          <div>
            <label for="departure-time">
              Select your departure time [UTC]:
            </label>

            <input
              type="datetime-local"
              step="900"
              id="departure-time"
              name="departure-time"
              onChange={handleChange}
              value={departureTime}
            ></input>
          </div>
        )}
      </div>
      <div className="help">
        <button
          className="modal-button"
          onClick={(e) => {
            e.preventDefault();
            setDisableMap(true);
          }}
        >
          Help
        </button>
      </div>
      <Popup open={disableMap}>
        {(close) => {
          return (
            <div className="modal">
              <div className="modal-content">
                <div className="header"> INSTRUCTIONS </div>
                <p>1. Select a model, registration number and departure time</p>
                <p>
                  2. Click on the map's measurement tool (&#8614;) and begin
                  plotting. Another table will show up below the map with the
                  route details
                </p>
                <p>
                  3. Once plotting is done, de-select the measurement tool. Fill
                  the data requested on the left side of the map
                </p>
                <p>
                  4. Magnetic variation, temperature or wind direction/speed can
                  be defined for each leg on the bottom table if those values
                  were not filled before
                </p>
                <p>
                  5. If wind direction/speed are left empty on both tables, the
                  wind will be downloaded automatically from open-meteo.com
                </p>
                <p>
                  Check out the
                  <a href="https://youtu.be/18xu4DESu48" target={"_blank"} rel={"noreferrer"}> tutorial</a>
                </p>
                <button
                  className="modal-close"
                  onClick={(e) => {
                    e.preventDefault();
                    setDisableMap(false);
                    close();
                  }}
                >
                  Okay
                </button>
              </div>
            </div>
          );
        }}
      </Popup>
      <div className={`map-container ${disableMap ? "disabled" : ""}`}>
        <table className="map-table">
          <tbody>
            <tr>
              <td>
                <label htmlFor="departureElevation">Departure elevation</label>
                <input
                  name="departureElevation"
                  type="number"
                  placeholder="2269"
                  value={
                    routeParameters.departureElevation
                      ? routeParameters.departureElevation
                      : ""
                  }
                  onChange={handleChange}
                  required
                ></input>
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="arrivalElevation"> Arrival elevation</label>
                <input
                  name="arrivalElevation"
                  type="number"
                  placeholder="2269"
                  value={
                    routeParameters.arrivalElevation
                      ? routeParameters.arrivalElevation
                      : ""
                  }
                  onChange={handleChange}
                ></input>
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="alternativeElevation">
                  {" "}
                  Alternate elevation
                </label>
                <input
                  name="alternativeElevation"
                  type="number"
                  placeholder="2050"
                  value={
                    routeParameters.alternativeElevation
                      ? routeParameters.alternativeElevation
                      : ""
                  }
                  onChange={handleChange}
                ></input>
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="departureQNH"> Departure QNH</label>
                <input
                  name="departureQNH"
                  type="number"
                  placeholder="1013"
                  value={
                    routeParameters.departureQNH
                      ? routeParameters.departureQNH
                      : ""
                  }
                  onChange={handleChange}
                ></input>
              </td>
            </tr>
            {modelData.modelo &&
              (modelData.modelo.includes("172") ||
                modelData.modelo.includes("2008")) && (
                <tr className="inputrow">
                  <td>
                    <label htmlFor="cruiseRpm"> Cruise RPM</label>
                    <input
                      className="inputtooltip"
                      name="cruiseRpm"
                      type="number"
                      placeholder="2200"
                      value={
                        routeParameters.cruiseRpm
                          ? routeParameters.cruiseRpm
                          : ""
                      }
                      onChange={handleChange}
                    ></input>
                  </td>
                </tr>
              )}
            <tr>
              <td>
                <label htmlFor="routeTemperature"> Departure temperature</label>
                <input
                  name="routeTemperature"
                  type="number"
                  placeholder="10"
                  value={
                    routeParameters.routeTemperature
                      ? routeParameters.routeTemperature
                      : ""
                  }
                  onChange={handleChange}
                ></input>
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="routeMagneticVariation">Magnetic var</label>
                <input
                  name="routeMagneticVariation"
                  type="number"
                  placeholder="0"
                  value={
                    routeParameters.routeMagneticVariation
                      ? routeParameters.routeMagneticVariation
                      : ""
                  }
                  onChange={handleChange}
                ></input>
              </td>
            </tr>
            <tr className="inputrow">
              <td>
                <label htmlFor="routeWindDirection"> Wind direction</label>

                <input
                  className="inputtooltip"
                  name="routeWindDirection"
                  type="number"
                  placeholder="180"
                  value={
                    routeParameters.routeWindDirection
                      ? routeParameters.routeWindDirection
                      : ""
                  }
                  onChange={handleChange}
                ></input>
              </td>
            </tr>
            <tr className="inputrow">
              <td>
                <label htmlFor="routeWindSpeed"> Wind speed</label>
                <input
                  className="inputtooltip"
                  name="routeWindSpeed"
                  type="number"
                  placeholder="2"
                  value={
                    routeParameters.routeWindSpeed
                      ? routeParameters.routeWindSpeed
                      : ""
                  }
                  onChange={handleChange}
                ></input>
              </td>
            </tr>
          </tbody>
        </table>
        <div id="map"></div>
      </div>
      <div className="upload-container">
        <input
          type={"file"}
          id={"csvFileInput"}
          accept={".JSON"}
          onChange={handleUpload}
          className="custom-file-input"
        />

        <button
          onClick={(e) => {
            handleOnSubmit(e);
          }}
          className="upload-button"
        >
          Upload Flight Plan
        </button>
        <button type="button" onClick={exportData} className="upload-button">
          Save Flight Plan
        </button>
      </div>
      {modelData.modelo && modelData.matricula && departureTime && (
        <div>
          {(() => {
            switch (modelData.modelo) {
              case "Cessna 172":
                return (
                  <OFPC172
                    modelData={modelData}
                    mapValues={mapValues}
                    routeParameters={routeParameters}
                    currentRoute={currentRoute}
                    departureTime={departureTime}
                  />
                );

              case "Tecnam P-Mentor":
                return (
                  <OFPPMENTOR
                    modelData={modelData}
                    mapValues={mapValues}
                    routeParameters={routeParameters}
                    currentRoute={currentRoute}
                    departureTime={departureTime}
                  />
                );

              case "PA28 Arrow":
                return (
                  <OFPPA28
                    modelData={modelData}
                    mapValues={mapValues}
                    routeParameters={routeParameters}
                    currentRoute={currentRoute}
                    departureTime={departureTime}
                  />
                );

              case "Diamond 40":
                return (
                  <OFPDA40
                    modelData={modelData}
                    mapValues={mapValues}
                    routeParameters={routeParameters}
                    currentRoute={currentRoute}
                    departureTime={departureTime}
                  />
                );

              case "Tecnam P2006T":
                return (
                  <OFPP2006T
                    modelData={modelData}
                    mapValues={mapValues}
                    routeParameters={routeParameters}
                    currentRoute={currentRoute}
                    departureTime={departureTime}
                  />
                );

              case "PA34 SENECA":
                return (
                  <OFPPA34
                    modelData={modelData}
                    mapValues={mapValues}
                    routeParameters={routeParameters}
                    currentRoute={currentRoute}
                    departureTime={departureTime}
                  />
                );

              default:
                return;
            }
          })()}
        </div>
      )}
      <div className="points-table">
        {!!mapValues.distances.length && (
          <div className="points-table-labels">
            <li>FROM</li>
            <li>TO</li>
            <li>DISTANCE</li>
            <li>TRACK</li>
            <li>ALTITUDE</li>
            {!routeParameters.routeWindDirection && <li>WIND DIR</li>}
            {!routeParameters.routeWindSpeed && <li>WIND SPD</li>}
            {!routeParameters.routeMagneticVariation && <li>VAR</li>}
            {!routeParameters.routeTemperature && <li>TEMP</li>}
          </div>
        )}
        {mapValues.distances.map((distance, i) => (
          <div key={i}>
            <form>
              <input
                name={`waypoint` + i}
                id={i}
                placeholder={`Point ${i}`}
                type="text"
                value={mapValues.waypoints[i] ? mapValues.waypoints[i] : ""}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
              ></input>
              <input
                name={`waypoint` + (i + 1)}
                id={i + 1}
                placeholder={`Point ${i + 1}`}
                type="text"
                value={
                  mapValues.waypoints[i + 1] ? mapValues.waypoints[i + 1] : ""
                }
                onChange={handleChange}
                onKeyDown={handleKeyDown}
              ></input>
              <input
                name={`distance` + i}
                key={`distance` + i}
                type="number"
                id={i}
                value={distance}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
              ></input>
              <input
                name={`bearing` + i}
                key={`bearing` + i}
                id={i}
                type="number"
                value={mapValues.bearings[i]}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
              ></input>
              <input
                name={`altitude` + i}
                key={`altitude` + i}
                id={i}
                type="number"
                placeholder={`Altitude ${i}`}
                value={mapValues.altitudes[i] ? mapValues.altitudes[i] : ""}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
              ></input>
              {!routeParameters.routeWindDirection && (
                <input
                  name={`windDirection` + i}
                  key={`windDirection` + i}
                  id={i}
                  type="number"
                  placeholder={`Wind direction ${i}`}
                  value={
                    mapValues.windDirection[i] ? mapValues.windDirection[i] : ""
                  }
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                ></input>
              )}
              {!routeParameters.routeWindSpeed && (
                <input
                  name={`windSpeed` + i}
                  key={`windSpeed` + i}
                  id={i}
                  type="number"
                  placeholder={`Wind speed ${i}`}
                  value={mapValues.windSpeed[i] ? mapValues.windSpeed[i] : ""}
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                ></input>
              )}
              {!routeParameters.routeMagneticVariation && (
                <input
                  name={`magneticVariation` + i}
                  key={`magneticVariation` + i}
                  id={i}
                  type="number"
                  placeholder={`Magnetic var ${i}`}
                  value={
                    mapValues.magneticVariation[i]
                      ? mapValues.magneticVariation[i]
                      : ""
                  }
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                ></input>
              )}
              {!routeParameters.routeTemperature && (
                <input
                  name={`temperature` + i}
                  key={`temperature` + i}
                  id={i}
                  type="number"
                  placeholder={`Temperature ${i}`}
                  value={
                    mapValues.temperature[i] ? mapValues.temperature[i] : ""
                  }
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                ></input>
              )}
            </form>
          </div>
        ))}
      </div>
      <footer className="about">
        <Link to="/about" target="_blank" rel="noopener noreferrer">
          <button className="about-btn">About the authors</button>
        </Link>
      </footer>

    </div>
    
  );
}
