import { PDFDocument, rgb } from "pdf-lib";
import download from "downloadjs";
import da40 from "./PDFTemplates/P2006T.pdf";
import Popup from "reactjs-popup";
import React, { useState } from "react";

const PDFLIB = ({ inputData, modelData }) => {
  const bew = [modelData.peso, modelData.cg, modelData.momento];

  const [disableMap, setDisableMap] = useState(false);

  const fpax = [
    Number(inputData.p1) + Number(inputData.p2),
    modelData.fpax,
    modelData.fpax * (Number(inputData.p1) + Number(inputData.p2)),
  ];

  const rpax1 = [
    Number(inputData.rpax1),
    modelData.rpax1,
    modelData.rpax1 * Number(inputData.rpax1),
  ];

  const bag1 = [
    Number(inputData.bag1),
    modelData.bag1,
    modelData.bag1 * Number(inputData.bag1),
  ];

  const fob = [
    (Number(inputData.fob) * 6) / 2.2046,
    modelData.fob,
    (Number(inputData.fob) * modelData.fob * 6) / 2.21,
  ];

  const tow = bew[0] + fpax[0] + rpax1[0] + fob[0] + bag1[0];

  function handleClick(e) {
    e.preventDefault();
    modifyPdf();
  }

  async function modifyPdf() {
    const existingPdfBytes = await fetch(da40).then((res) => res.arrayBuffer());

    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    // Get the first page of the document
    const pages = pdfDoc.getPages();
    const firstPage = pages[0];

    const esquina1 = [101.5, 379];
    const boxWidth1 = 111.5;
    const boxHeight = 229;
    const esquina2 = [217.3, 379];
    const esquina3 = [333, 379];
    const boxWidth3 = 76;
    const esquina4 = [415, 379];
    const boxWidth4 = 40.5;
    const esquina5 = [469, 379];
    const boxWidth5 = 66.75;

    const x10 = esquina1[0];
    const y10 = esquina1[1] + (boxHeight * (bew[2] - 140)) / (500 - 140);
    const x11 = esquina1[0] + (fpax[0] * boxWidth1) / 220;
    const y11 = esquina1[1] + ((bew[2] - 140 + (-8 / 9) * fpax[0]) * 229) / 360;
    const x20 = esquina2[0];
    const y20 = y11;
    const x21 = x20 + (rpax1[0] * boxWidth1) / 220;
    const y21 = y20 + ((1 / 9) * rpax1[0] * 229) / 360;
    const x30 = esquina3[0];
    const y30 = y21;
    const x31 = x30 + (fob[0] * boxWidth3) / 150;
    const y31 = y30 + ((3 / 4) * fob[0] * 229) / 360;
    const x40 = esquina4[0];
    const y40 = y31;
    const x41 = x40 + (bag1[0] * boxWidth4) / 80;
    const y41 = y40 + (1.2 * bag1[0] * 229) / 360;
    const x5 = esquina5[0] + ((tow - 900) * boxWidth5) / (1230 - 900);
    const y5 = y41;

    firstPage.drawCircle({
      x: x10,
      y: y10,
      size: 3,
      borderColor: rgb(1, 0, 0),
    });

    firstPage.drawLine({
      start: { x: x10, y: y10 },
      end: { x: x11, y: y11 },
      color: rgb(1, 0, 0),
      thickness: 1,
    });

    firstPage.drawLine({
      start: { x: x11, y: y11 },
      end: { x: x20, y: y20 },
      color: rgb(1, 0, 0),
      thickness: 1,
    });

    firstPage.drawLine({
      start: { x: x20, y: y20 },
      end: { x: x21, y: y21 },
      color: rgb(1, 0, 0),
      thickness: 1,
    });

    firstPage.drawLine({
      start: { x: x21, y: y21 },
      end: { x: x30, y: y30 },
      color: rgb(1, 0, 0),
      thickness: 1,
    });

    firstPage.drawLine({
      start: { x: x30, y: y30 },
      end: { x: x31, y: y31 },
      color: rgb(1, 0, 0),
      thickness: 1,
    });

    firstPage.drawLine({
      start: { x: x31, y: y31 },
      end: { x: x40, y: y40 },
      color: rgb(1, 0, 0),
      thickness: 1,
    });

    firstPage.drawLine({
      start: { x: x40, y: y40 },
      end: { x: x41, y: y41 },
      color: rgb(1, 0, 0),
      thickness: 1,
    });

    firstPage.drawLine({
      start: { x: x41, y: y41 },
      end: { x: x5, y: y5 },
      color: rgb(1, 0, 0),
      thickness: 1,
    });

    firstPage.drawCircle({
      x: x5,
      y: y5,
      size: 3,
      borderColor: rgb(1, 0, 0),
    });

    firstPage.drawLine({
      start: { x: x5, y: esquina5[1] },
      end: { x: x5, y: esquina5[1] + boxHeight },
      color: rgb(1, 0, 0),
      thickness: 1,
      dashArray: [12, 6],
    });

    // Serialize the PDFDocument to bytes (a Uint8Array)
    const pdfBytes = await pdfDoc.save();

    // Trigger the browser to download the PDF document
    download(pdfBytes, "M&B P2006T.pdf", "application/pdf");
  }

  return (
    <div className="graph-container">
      <div>
        <button
          className="boton-generar-pdf"
          onClick={(e) => {
            handleClick(e);
            setDisableMap(true);
          }}
        >
          Download M&B
        </button>
        <Popup open={disableMap}>
          {(close) => {
            return (
              <div className="modal">
                <div className="modal-content">
                  <div className="header"> DISCLAIMER </div>
                  <p>DATA MAY BE OUT OF DATE</p>
                  <p>USE AT OWN RISK</p>
                  <p>You have to know how to get to these results by hand</p>
                  <p>YOU MAY BE ASKED ABOUT IT IN YOUR PRE-FLIGHT BRIEFING</p>

                  <button
                    className="modal-close"
                    onClick={(e) => {
                      e.preventDefault();
                      setDisableMap(false);
                      close();
                    }}
                  >
                    Okay
                  </button>
                </div>
              </div>
            );
          }}
        </Popup>
      </div>
    </div>
  );
};

export default PDFLIB;
