import React from "react";
import { Link } from "react-router-dom";

import { ReactComponent as LogoSvg } from "./PDFTemplates/logo.svg";

function Meteo() {
  function formatDateToYYYYMMDD(date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Add leading zero if necessary
    const day = date.getDate().toString().padStart(2, '0'); // Add leading zero if necessary
    return `${year}${month}${day}`;
  }
  
  const now = new Date();
  const antesDeAyer = new Date(now);
  antesDeAyer.setDate(now.getDate() - 2);
  
  const ayer = new Date(now);
  ayer.setDate(now.getDate() - 1);
  
  const hoy = new Date(now);
  
  const maniana = new Date(now);
  maniana.setDate(now.getDate() + 1);
  
  const antesDeAyerURL = formatDateToYYYYMMDD(antesDeAyer);
  const ayerURL = formatDateToYYYYMMDD(ayer);
  const hoyURL = formatDateToYYYYMMDD(hoy);
  const manianaURL = formatDateToYYYYMMDD(maniana);

  return (
    <div className="main-container">
      <div className="nav-container">
        <Link to="/">
          <button className="nav-logo">
            <LogoSvg />
          </button>
        </Link>
        <div className="nav-buttons">
          <Link to="/flightplan">
            <button className="button">Flight Plan</button>
          </Link>
          <Link to="/massandbalance">
            <button className="button btn-mb">Mass & Balance</button>
          </Link>
          <Link to="/meteo">
            <button className="button active-page-btn">Meteo</button>
          </Link>
        </div>
        <a
          href="https://www.paypal.com/donate/?hosted_button_id=RAZU6S7ZT5DS2"
          target="_blank"
          rel="noreferrer"
        >
          <button className="boton-generar-pdf">Donate</button>
        </a>
      </div>
      <div className="meteo-container">
        <h1>SIGWX</h1>
        <div className="meteo-links">
          <a
            href={
              "http://ama.aemet.es/o/estaticos/bbdd/imagenes/QGQE70LEMM1200________" +
              antesDeAyerURL +
              ".png"
            }
            color="#fff4f4"
            text-decoration-line="initial"
            target="_blank"
            rel="noreferrer"
          >
            {ayer.getDate() +
              " / " +
              (ayer.getMonth() + 1 > 9
                ? ayer.getMonth() + 1
                : "0" + (ayer.getMonth() + 1))}{" "}
            00:00 Z
          </a>
          <a
            href={
              "http://ama.aemet.es/o/estaticos/bbdd/imagenes/QGQE70LEMM1800________" +
              antesDeAyerURL +
              ".png"
            }
            color="#fff4f4"
            text-decoration-line="initial"
            target="_blank"
            rel="noreferrer"
          >
            {ayer.getDate() +
              " / " +
              (ayer.getMonth() + 1 > 9
                ? ayer.getMonth() + 1
                : "0" + (ayer.getMonth() + 1))}{" "}
            06:00 Z
          </a>
          <a
            href={
              "http://ama.aemet.es/o/estaticos/bbdd/imagenes/QGQE70LEMM0000________" +
              ayerURL +
              ".png"
            }
            color="#fff4f4"
            text-decoration-line="initial"
            target="_blank"
            rel="noreferrer"
          >
            {ayer.getDate() +
              " / " +
              (ayer.getMonth() + 1 > 9
                ? ayer.getMonth() + 1
                : "0" + (ayer.getMonth() + 1))}{" "}
            12:00 Z
          </a>
          <a
            href={
              "http://ama.aemet.es/o/estaticos/bbdd/imagenes/QGQE70LEMM0600________" +
              ayerURL +
              ".png"
            }
            color="#fff4f4"
            text-decoration-line="initial"
            target="_blank"
            rel="noreferrer"
          >
            {ayer.getDate() +
              " / " +
              (ayer.getMonth() + 1 > 9
                ? ayer.getMonth() + 1
                : "0" + (ayer.getMonth() + 1))}{" "}
            18:00 Z
          </a>
          <a
            href={
              "http://ama.aemet.es/o/estaticos/bbdd/imagenes/QGQE70LEMM1200________" +
              ayerURL +
              ".png"
            }
            color="#fff4f4"
            text-decoration-line="initial"
            target="_blank"
            rel="noreferrer"
          >
            {hoy.getDate() +
              " / " +
              (hoy.getMonth() + 1 > 9
                ? hoy.getMonth() + 1
                : "0" + (hoy.getMonth() + 1))}{" "}
            00:00 Z
          </a>
          <a
            href={
              "http://ama.aemet.es/o/estaticos/bbdd/imagenes/QGQE70LEMM1800________" +
              ayerURL +
              ".png"
            }
            color="#fff4f4"
            text-decoration-line="initial"
            target="_blank"
            rel="noreferrer"
          >
            {hoy.getDate() +
              " / " +
              (hoy.getMonth() + 1 > 9
                ? hoy.getMonth() + 1
                : "0" + (hoy.getMonth() + 1))}{" "}
            06:00 Z
          </a>
          <a
            href={
              "http://ama.aemet.es/o/estaticos/bbdd/imagenes/QGQE70LEMM0000________" +
              hoyURL +
              ".png"
            }
            color="#fff4f4"
            text-decoration-line="initial"
            target="_blank"
            rel="noreferrer"
          >
            {hoy.getDate() +
              " / " +
              (hoy.getMonth() + 1 > 9
                ? hoy.getMonth() + 1
                : "0" + (hoy.getMonth() + 1))}{" "}
            12:00 Z
          </a>
          <a
            href={
              "http://ama.aemet.es/o/estaticos/bbdd/imagenes/QGQE70LEMM0600________" +
              hoyURL +
              ".png"
            }
            color="#fff4f4"
            text-decoration-line="initial"
            target="_blank"
            rel="noreferrer"
          >
            {hoy.getDate() +
              " / " +
              (hoy.getMonth() + 1 > 9
                ? hoy.getMonth() + 1
                : "0" + (hoy.getMonth() + 1))}{" "}
            18:00 Z
          </a>
          <a
            href={
              "http://ama.aemet.es/o/estaticos/bbdd/imagenes/QGQE70LEMM1200________" +
              hoyURL +
              ".png"
            }
            color="#fff4f4"
            text-decoration-line="initial"
            target="_blank"
            rel="noreferrer"
          >
            {maniana.getDate() +
              " / " +
              (maniana.getMonth() + 1 > 9
                ? maniana.getMonth() + 1
                : "0" + (maniana.getMonth() + 1))}{" "}
            00:00 Z
          </a>
          <a
            href={
              "http://ama.aemet.es/o/estaticos/bbdd/imagenes/QGQE70LEMM1800________" +
              hoyURL +
              ".png"
            }
            color="#fff4f4"
            text-decoration-line="initial"
            target="_blank"
            rel="noreferrer"
          >
            {maniana.getDate() +
              " / " +
              (maniana.getMonth() + 1 > 9
                ? maniana.getMonth() + 1
                : "0" + (maniana.getMonth() + 1))}{" "}
            06:00 Z
          </a>

          <a
            href={
              "http://ama.aemet.es/o/estaticos/bbdd/imagenes/QGQE70LEMM0000________" +
              manianaURL +
              ".png"
            }
            color="#fff4f4"
            text-decoration-line="initial"
            target="_blank"
            rel="noreferrer"
          >
            {maniana.getDate() +
              " / " +
              (maniana.getMonth() + 1 > 9
                ? maniana.getMonth() + 1
                : "0" + (maniana.getMonth() + 1))}{" "}
            12:00 Z
          </a>

          <a
            href={
              "http://ama.aemet.es/o/estaticos/bbdd/imagenes/QGQE70LEMM0600________" +
              manianaURL +
              ".png"
            }
            color="#fff4f4"
            text-decoration-line="initial"
            target="_blank"
            rel="noreferrer"
          >
            {maniana.getDate() +
              " / " +
              (maniana.getMonth() + 1 > 9
                ? maniana.getMonth() + 1
                : "0" + (maniana.getMonth() + 1))}{" "}
            18:00 Z
          </a>
        </div>
        <div>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://embed.windy.com/embed2.html?lat=40.302&lon=-3.940&detailLat=40.106&detailLon=-3.922&width=650&height=450&zoom=9&level=900h&overlay=wind&product=ecmwf&menu=&message=true&marker=true&calendar=now&pressure=&type=map&location=coordinates&detail=&metricWind=kt&metricTemp=%C2%B0C&radarRange=-1"
          >
            <button>Windy</button>
          </a>
          <a target="_blank" rel="noreferrer" href="https://metar-taf.com/LEVS">
            <button>METAR/TAF</button>
          </a>
          <a target="_blank" rel="noreferrer" href="https://my.meteoblue.com/images/meteogram?temperature_units=C&wind_units=kn&precipitation_units=mm&winddirection=3char&iso2=es&lat=40.3707&lon=-3.78514&asl=690&tz=Europe%2FMadrid&dpi=72&apikey=jhMJTOUVRNvs25m4&lang=en&location_name=Madrid-Cuatro+Vientos+Airport&windspeed_units=kn&sig=40d4bce85522601c5f84697e8ed2759d">
            <button>MeteoBlue</button>
          </a>
        </div>
      </div>
      <footer className="about">
        <Link to="/about" target="_blank" rel="noopener noreferrer">
          <button className="about-btn">About the authors</button>
        </Link>
      </footer>
    </div>
  );
}

export default Meteo;
