import { PDFDocument, rgb } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";
import download from "downloadjs";
import pmentor from "./PDFTemplates/PMENTOR.pdf";
import fuente from "./Fonts/Arial.ttf";
import { Chart } from "react-google-charts";
import { dataPMENTOR, optionsPMENTOR } from "./PDFTemplates/Graficas.js";
import Popup from "reactjs-popup";
import React, { useState } from "react";

const PDFPMENTOR = ({ inputData, modelData }) => {
  const [disableMap, setDisableMap] = useState(false);
  const bew = [modelData.peso, modelData.cg, modelData.momento];

  const alumno = [
    Number(inputData.p1),
    modelData.fpax,
    modelData.fpax * Number(inputData.p1),
  ];

  const instructor = [
    Number(inputData.p2),
    modelData.fpax,
    modelData.fpax * Number(inputData.p2),
  ];

  var fpax = alumno.map((v, i) => v + instructor[i]);

  const bag1 = [
    Number(inputData.bag1),
    modelData.bag1,
    modelData.bag1 * Number(inputData.bag1),
  ];

  const fob = [
    Number(inputData.fob) * 6,
    modelData.fob,
    Number(inputData.fob) * modelData.fob * 6,
  ];

  const tf = [
    Number(inputData.tf) * 6,
    modelData.fob,
    Number(inputData.tf) * modelData.fob * 6,
  ];

  var datosIntroducidos = [
    [bew[0], bew[1], bew[2]],
    [alumno[0], "1.804", alumno[2]],
    [instructor[0], "1.804", instructor[2]],
    [bag1[0], "2.26", bag1[2]],
    [fob[0] / 2.21, "2.139", fob[2] / 2.21],
    [
      bew[0] + fob[0] / 2.21 + bag1[0] + fpax[0],
      "",
      bew[2] + fob[2] / 2.21 + bag1[2] + fpax[2],
    ],
    [tf[0] / 2.21, "2.139", tf[2] / 2.21],
    [
      bew[0] + fob[0] / 2.21 + bag1[0] + fpax[0] - tf[0] / 2.21,
      "",
      bew[2] + fob[2] / 2.21 + bag1[2] + fpax[2] - tf[2] / 2.21,
    ],
  ];

  dataPMENTOR[dataPMENTOR.length - 2][0] = datosIntroducidos[5][2];
  dataPMENTOR[dataPMENTOR.length - 2][2] = datosIntroducidos[5][0];

  dataPMENTOR[dataPMENTOR.length - 1][0] = datosIntroducidos[7][2];
  dataPMENTOR[dataPMENTOR.length - 1][4] = datosIntroducidos[7][0];

  function handleClick(e) {
    e.preventDefault();
    modifyPdf();
  }

  async function modifyPdf() {
    const existingPdfBytes = await fetch(pmentor).then((res) =>
      res.arrayBuffer()
    );

    const fontBytes = await fetch(fuente).then((res) => res.arrayBuffer());

    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    pdfDoc.registerFontkit(fontkit);
    const ubuntuFont = await pdfDoc.embedFont(fontBytes);

    // Get the first page of the document
    const pages = pdfDoc.getPages();
    const firstPage = pages[0];

    var datosIntroducidos = [
      [
        bew[0].toFixed(3).toString(),
        bew[1].toFixed(3).toString(),
        bew[2].toFixed(3).toString(),
      ],
      [
        alumno[0].toFixed(3).toString(),
        "1.804",
        alumno[2].toFixed(3).toString(),
      ],
      [
        instructor[0].toFixed(3).toString(),
        "1.804",
        instructor[2].toFixed(3).toString(),
      ],
      [bag1[0].toFixed(3).toString(), "2.26", bag1[2].toFixed(3).toString()],
      [
        (fob[0] / 2.21).toFixed(3).toString(),
        "2.139",
        (fob[2] / 2.21).toFixed(3).toString(),
      ],
      [
        (bew[0] + fob[0] / 2.21 + bag1[0] + fpax[0]).toFixed(3).toString(),
        "",
        (bew[2] + fob[2] / 2.21 + bag1[2] + fpax[2]).toFixed(3).toString(),
      ],
      [
        (tf[0] / 2.21).toFixed(3).toString(),
        "2.139",
        (tf[2] / 2.21).toFixed(3).toString(),
      ],
      [
        (bew[0] + fob[0] / 2.21 + bag1[0] + fpax[0] - tf[0] / 2.21)
          .toFixed(3)
          .toString(),
        "",
        (bew[2] + fob[2] / 2.21 + bag1[2] + fpax[2] - tf[2] / 2.21)
          .toFixed(3)
          .toString(),
      ],
    ];

    const fontSize = 10;

    const textHeight = ubuntuFont.heightAtSize(fontSize);

    const anchoCeldas = [224.5 - 168.7, 275.2 - 224.5, 352.3 - 275.2];
    const anchoCeldasSumados = [
      0,
      anchoCeldas[0],
      anchoCeldas[0] + anchoCeldas[1],
    ];

    const esquinaCeldasY = [448.3, 416.5, 400, 381, 342, 297, 258, 228];
    const altoCeldas = [
      466.3 - 448.3,
      432 - 416.5,
      416.5 - 400,
      400 - 381,
      381 - 342,
      329.3 - 297,
      297 - 258,
      258 - 228,
    ];

    var k = 0;
    var l = 0;
    while (l < altoCeldas.length) {
      while (k < anchoCeldas.length) {
        firstPage.drawText(datosIntroducidos[l][k], {
          x:
            168.7 +
            anchoCeldas[k] / 2 +
            anchoCeldasSumados[k] -
            ubuntuFont.widthOfTextAtSize(datosIntroducidos[l][k], fontSize) / 2,
          y: esquinaCeldasY[l] + altoCeldas[l] / 2 - textHeight / 3,
          font: ubuntuFont,
          size: fontSize,
          color: rgb(0, 0, 0),
        });

        k++;
      }
      k = 0;
      l++;
    }

    firstPage.drawCircle({
      x: 708 - ((datosIntroducidos[5][0] - 450) * (708 - 505)) / (750 - 450),
      y:
        150.7 +
        ((datosIntroducidos[5][2] - 750) * (499 - 150.7)) / (1400 - 750),
      size: 3.5,
      color: rgb(1, 0, 0),
    });

    firstPage.drawText("T/O", {
      x:
        708 -
        ((datosIntroducidos[5][0] - 450) * (708 - 505)) / (750 - 450) +
        10,
      y:
        150.7 +
        ((datosIntroducidos[5][2] - 750) * (499 - 150.7)) / (1400 - 750) -
        fontSize / 3,
      font: ubuntuFont,
      size: fontSize,
      color: rgb(1, 0, 0),
    });

    firstPage.drawCircle({
      x: 708 - ((datosIntroducidos[7][0] - 450) * (708 - 505)) / (750 - 450),
      y:
        150.7 +
        ((datosIntroducidos[7][2] - 750) * (499 - 150.7)) / (1400 - 750),
      size: 3.5,
      color: rgb(1, 0, 0),
    });

    firstPage.drawText("LDG", {
      x:
        708 -
        ((datosIntroducidos[7][0] - 450) * (708 - 505)) / (750 - 450) +
        10,
      y:
        150.7 +
        ((datosIntroducidos[7][2] - 750) * (499 - 150.7)) / (1400 - 750) -
        fontSize / 3,
      font: ubuntuFont,
      size: fontSize,
      color: rgb(1, 0, 0),
    });

    // Serialize the PDFDocument to bytes (a Uint8Array)
    const pdfBytes = await pdfDoc.save();

    // Trigger the browser to download the PDF document
    download(pdfBytes, "M&B PMENTOR.pdf", "application/pdf");
  }

  return (
    <div className="graph-container">
      <div className="graph">
        <Chart
          chartType="LineChart"
          data={dataPMENTOR}
          options={optionsPMENTOR}
          style={{ textShadow: "0 0 0 " }}
        />
      </div>
      <div>
        <button
          className="boton-generar-pdf"
          onClick={(e) => {
            handleClick(e);
            setDisableMap(true);
          }}
        >
          Download M&B
        </button>
        <Popup open={disableMap}>
          {(close) => {
            return (
              <div className="modal">
                <div className="modal-content">
                  <div className="header"> DISCLAIMER </div>
                  <p>DATA MAY BE OUT OF DATE</p>
                  <p>USE AT OWN RISK</p>
                  <p>You have to know how to get to these results by hand</p>
                  <p>YOU MAY BE ASKED ABOUT IT IN YOUR PRE-FLIGHT BRIEFING</p>

                  <button
                    className="modal-close"
                    onClick={(e) => {
                      e.preventDefault();
                      setDisableMap(false);
                      close();
                    }}
                  >
                    Okay
                  </button>
                </div>
              </div>
            );
          }}
        </Popup>
      </div>
    </div>
  );
};

export default PDFPMENTOR;
