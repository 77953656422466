import { degrees, PDFDocument, rgb } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";
import download from "downloadjs";
import pa34 from "./PDFTemplates/Seneca2.pdf";
import fuente from "./Fonts/Calibri.ttf";
import Popup from "reactjs-popup";
import React, { useState } from "react";

const PDFLIB = ({ inputData, modelData }) => {
  const [disableMap, setDisableMap] = useState(false);
  const bew = [modelData.peso, modelData.cg, modelData.momento];

  const fpax = [
    Number(inputData.p1) + Number(inputData.p2),
    modelData.fpax,
    modelData.fpax * (Number(inputData.p1) + Number(inputData.p2)),
  ];

  const rpax1 = [
    Number(inputData.rpax1),
    modelData.rpax1,
    modelData.rpax1 * Number(inputData.rpax1),
  ];

  const rpax2 = [
    Number(inputData.rpax2),
    modelData.rpax2,
    modelData.rpax2 * Number(inputData.rpax2),
  ];

  const rpax3 = [
    Number(inputData.rpax3),
    modelData.rpax3,
    modelData.rpax3 * Number(inputData.rpax3),
  ];

  const rpax4 = [
    Number(inputData.rpax4),
    modelData.rpax1,
    modelData.rpax1 * Number(inputData.rpax4),
  ];

  const bag1 = [
    Number(inputData.bag1),
    modelData.bag1,
    modelData.bag1 * Number(inputData.bag1),
  ];

  const bag2 = [
    Number(inputData.bag2),
    modelData.bag2,
    modelData.bag2 * Number(inputData.bag2),
  ];

  const fob = [
    Number(inputData.fob),
    modelData.fob,
    Number(inputData.fob) * modelData.fob,
  ];

  const tf = [
    Number(inputData.tf) * 6,
    modelData.fob,
    Number(inputData.tf) * modelData.fob * 6,
  ];

  const zfww =
    bew[0] +
    (fpax[0] + rpax1[0] + rpax2[0] + rpax3[0] + rpax4[0] + bag1[0] + bag2[0]) *
      2.21;
  const zfwm =
    bew[2] +
    (fpax[2] + rpax1[2] + rpax2[2] + rpax3[2] + rpax4[2] + bag1[2] + bag2[2]) *
      2.21;

  const zfw = [zfww, zfwm / zfww, zfwm];

  const toww = zfw[0] + fob[0] * 6;
  const towm = zfw[2] + fob[2] * 6;

  const tow = [toww, towm / toww, towm];

  const ldw = [toww - tf[0], (towm - tf[2]) / (toww - tf[0]), towm - tf[2]];

  function handleClick(e) {
    e.preventDefault();
    modifyPdf();
  }

  async function modifyPdf() {
    const existingPdfBytes = await fetch(pa34).then((res) => res.arrayBuffer());

    const fontBytes = await fetch(fuente).then((res) => res.arrayBuffer());

    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    pdfDoc.registerFontkit(fontkit);
    const ubuntuFont = await pdfDoc.embedFont(fontBytes);

    // Get the first page of the document
    const pages = pdfDoc.getPages();
    const firstPage = pages[0];

    var variables = [
      [
        bew[0].toFixed(2).toString(),
        bew[1].toFixed(2).toString(),
        bew[2].toFixed(2).toString(),
      ],
      [
        (2.21 * fpax[0]).toFixed(2).toString(),
        "85.5",
        (2.21 * fpax[2]).toFixed(2).toString(),
      ],
      [
        (2.21 * rpax1[0]).toFixed(2).toString(),
        "118.1",
        (2.21 * rpax1[2]).toFixed(2).toString(),
      ],
      [
        (2.21 * rpax2[0]).toFixed(2).toString(),
        "119.1",
        (2.21 * rpax2[2]).toFixed(2).toString(),
      ],
      [
        (2.21 * rpax3[0]).toFixed(2).toString(),
        "157.6",
        (2.21 * rpax3[2]).toFixed(2).toString(),
      ],
      [
        (2.21 * rpax4[0]).toFixed(2).toString(),
        "118.1",
        (2.21 * rpax4[2]).toFixed(2).toString(),
      ],
      [
        (2.21 * bag1[0]).toFixed(2).toString(),
        "22.5",
        (2.21 * bag1[2]).toFixed(2).toString(),
      ],
      [
        (2.21 * bag2[0]).toFixed(2).toString(),
        "178.7",
        (2.21 * bag2[2]).toFixed(2).toString(),
      ],
      [
        zfw[0].toFixed(2).toString(),
        zfw[1].toFixed(2).toString(),
        zfw[2].toFixed(2).toString(),
      ],
      [
        (fob[0] * 6).toFixed(2).toString(),
        "93.6",
        (fob[2] * 6).toFixed(2).toString(),
      ],
      [
        tow[0].toFixed(2).toString(),
        tow[1].toFixed(2).toString(),
        tow[2].toFixed(2).toString(),
      ],
    ];

    const fontSize = 12;

    const textHeight = ubuntuFont.heightAtSize(fontSize);

    const esquina = [308.5, 575.5];
    const boxWidth = 384 - 308;
    var boxHeight = (595.5 - 431) / 8;
    const boxX = esquina[0];
    const boxY = esquina[1];

    var i = 0;
    while (i < 8) {
      var j = 0;
      while (j < 2) {
        firstPage.drawText(variables[i][j], {
          x:
            boxX +
            boxWidth / 2 -
            ubuntuFont.widthOfTextAtSize(variables[i][j], fontSize) / 2 +
            j * boxWidth,
          y: boxY + boxHeight / 2 - textHeight / 3 - i * boxHeight,
          font: ubuntuFont,
          size: fontSize,
          color: rgb(0, 0, 0),
        });
        j++;

        firstPage.drawText(variables[i][2], {
          x:
            460 +
            (545 - 460) / 2 -
            ubuntuFont.widthOfTextAtSize(variables[i][2], fontSize) / 2,
          y: boxY + boxHeight / 2 - textHeight / 3 - i * boxHeight,
          font: ubuntuFont,
          size: fontSize,
          color: rgb(0, 0, 0),
        });
      }
      i++;
    }

    var i = 0;
    while (i < 2) {
      var j = 0;
      while (j < 2) {
        firstPage.drawText(variables[i+8][j], {
          x:
            boxX +
            boxWidth / 2 -
            ubuntuFont.widthOfTextAtSize(variables[i+8][j], fontSize) / 2 +
            j * boxWidth,
          y: 404 + (431 - 404) / 2 - textHeight / 3 - i * (431 - 404),
          font: ubuntuFont,
          size: fontSize,
          color: rgb(0, 0, 0),
        });
        j++;

        firstPage.drawText(variables[i+8][2], {
          x:
            460 +
            (545 - 460) / 2 -
            ubuntuFont.widthOfTextAtSize(variables[i+8][2], fontSize) / 2,
          y: 404 + (431 - 404) / 2 - textHeight / 3 - i * (431 - 404),
          font: ubuntuFont,
          size: fontSize,
          color: rgb(0, 0, 0),
        });
      }
      i++;
    }

    var k = 0;
    while (k < 2) {
      firstPage.drawText(variables[10][k], {
        x:
          boxX +
          boxWidth / 2 -
          ubuntuFont.widthOfTextAtSize(variables[10][k], fontSize) / 2 +
          k * boxWidth,
        y: 356.5 + boxHeight / 2 - textHeight / 3,
        font: ubuntuFont,
        size: fontSize,
        color: rgb(0, 0, 0),
      });
      k++;
    }

    firstPage.drawText(variables[10][2], {
      x:
        460 +
        (545 - 460) / 2 -
        ubuntuFont.widthOfTextAtSize(variables[10][2], fontSize) / 2,
      y: 356.5 + boxHeight / 2 - textHeight / 3,
      font: ubuntuFont,
      size: fontSize,
      color: rgb(0, 0, 0),
    });

    firstPage.drawText("EC-" + modelData.matricula, {
      x: 170,
      y: 755,
      font: ubuntuFont,
      size: fontSize,
      color: rgb(0, 0, 0),
    });

    var esq = [486, 121];

    var x0 =
      (((tow[0] - 2200) * (121 - 84)) / (486 - 288)) *
      ((486 - 288) / (3400 - 2200));
    var x1 =
      ((-1 * (tow[0] - 2200) * (260 - 336)) / (486 - 100)) *
      ((486 - 100) / (4570 - 2200));
    
    var x = 260 - 121 + x0 + x1;
    
    var xTO =
      esq[1] -
      x0 +
      ((tow[1] - 82) * ((260 - 121) / (94.6 - 82)) * x) / (260 - 121);
    
    var yTO = esq[0] - ((tow[0] - 2200) * (486 - 288)) / (3400 - 2200);

    var xZFW =
      esq[1] -
      x0 +
      ((zfw[1] - 82) * ((260 - 121) / (94.6 - 82)) * x) / (260 - 121);

    var yZFW = esq[0] - ((zfw[0] - 2200) * (486 - 288)) / (3400 - 2200);

    var xLDG =
      esq[1] -
      x0 +
      ((ldw[1] - 82) * ((260 - 121) / (94.6 - 82)) * x) / (260 - 121);
      
    var yLDG = esq[0] - ((ldw[0] - 2200) * (486 - 288)) / (3400 - 2200);

    firstPage.drawCircle({
      x: yZFW,
      y: xZFW,
      size: 3,
    });

    firstPage.drawText("ZFW", {
      x: yZFW,
      y: xZFW + 5,
      font: ubuntuFont,
      size: fontSize,
      rotate: degrees(90),
    });

    firstPage.drawCircle({
      x: yTO,
      y: xTO,
      size: 3,
    });

    firstPage.drawText("T/O", {
      x: yTO,
      y: xTO + 5,
      font: ubuntuFont,
      size: fontSize,
      rotate: degrees(90),
    });

    firstPage.drawCircle({
      x: yLDG,
      y: xLDG,
      size: 3,
    });

    firstPage.drawText("LDG", {
      x: yLDG,
      y: xLDG + 5,
      font: ubuntuFont,
      size: fontSize,
      rotate: degrees(90),
    });

    // Serialize the PDFDocument to bytes (a Uint8Array)
    const pdfBytes = await pdfDoc.save();

    // Trigger the browser to download the PDF document
    download(pdfBytes, "M&B PA-34 SENECA II.pdf", "application/pdf");
  }

  return (
    <div className="graph-container">
      <div>
        <button
          className="boton-generar-pdf"
          onClick={(e) => {
            handleClick(e);
            setDisableMap(true);
          }}
        >
          Download M&B
        </button>
        <Popup open={disableMap}>
          {(close) => {
            return (
              <div className="modal">
                <div className="modal-content">
                  <div className="header"> DISCLAIMER </div>
                  <p>DATA MAY BE OUT OF DATE</p>
                  <p>USE AT OWN RISK</p>
                  <p>You have to know how to get to these results by hand</p>
                  <p>YOU MAY BE ASKED ABOUT IT IN YOUR PRE-FLIGHT BRIEFING</p>

                  <button
                    className="modal-close"
                    onClick={(e) => {
                      e.preventDefault();
                      setDisableMap(false);
                      close();
                    }}
                  >
                    Okay
                  </button>
                </div>
              </div>
            );
          }}
        </Popup>
      </div>
    </div>
  );
};

export default PDFLIB;
