import React from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";

import { ReactComponent as LogoSvg } from "./PDFTemplates/logo.svg";
import yo from "./images/Yo.jpeg";
import pere from "./images/pere.jfif";
import { ReactComponent as LinkedIn } from "./PDFTemplates/LinkedInLogo.svg";

const About = () => {
  useEffect(() => {
    const metaDescription = document.getElementById("vista");
    metaDescription.setAttribute("content", "");
    console.log("got" + metaDescription.getAttribute("content"));
  }, []);

  return (
    <div className="main-container">
      <div className="nav-container">
        <Link to="/">
          <button className="nav-logo">
            <LogoSvg />
          </button>
        </Link>
        <div className="nav-buttons">
          <Link to="/flightplan">
            <button className="button">Flight Plan</button>
          </Link>
          <Link to="/massandbalance">
            <button className="button btn-mb">Mass & Balance</button>
          </Link>
          <Link to="/meteo">
            <button className="button">Meteo</button>
          </Link>
        </div>
        <a
          href="https://www.paypal.com/donate/?hosted_button_id=RAZU6S7ZT5DS2"
          target="_blank"
          rel="noreferrer"
        >
          <button className="boton-generar-pdf">Donate</button>
        </a>
      </div>
      <div className="about-container">
        <div className="about-authors">
          <div className="author">
            <h3>
              Alfredo Osiglia
              <a
                href="https://www.linkedin.com/in/alfredo-o-325861129/"
                target="_blank"
                rel="noreferrer"
              >
                <button className="linkedin-logo">
                  <LinkedIn />
                </button>
              </a>
            </h3>
            <img src={yo} alt="Alfredo Osiglia" />
            <h4>This website aims to be as simple as possible.</h4>
            <p>
              That's why Alfredo made use of his knowledge in JavaScript, HTML,
              CSS and ReactJS to develop all the arquitecture required for it to
              be compatible with as many devices as possible.
            </p>
          </div>

          <div className="author">
            <h3>
              Pedro Galán
              <a
                href="https://www.linkedin.com/in/pedro-luis-gal%C3%A1n-feliubadal%C3%B3-316498282/"
                target="_blank"
                rel="noreferrer"
              >
                <button className="linkedin-logo">
                  <LinkedIn />
                </button>
              </a>
            </h3>
            <img src={pere} alt="Pedro Galán" />
            <h4>Tried to make life easier.</h4>
            <p>
              After writing and calculating some operational flight plan logs by
              hand, the urge for automation sprouted. Pedro transformed all the
              files used by AEROTEC with pdf-lib, Google Charts and PDFBox.
            </p>
          </div>
        </div>

        <a href="mailto:pedro.galanfe@gmail.com">
          <button className="boton-contact-us">Contact Us</button>
        </a>
      </div>
    </div>
  );
};

export default About;
