const rutas = {
  "LOCAL CORTO": {
    "0": {
      "NAME": "LECU",
      "ALTITUDE": 2269,
      "COORDINATES": {lat: 40.370556, lng:-3.785278},
    },
    "1": {
      "NAME": "W",
      "ALTITUDE": 3000,
      "COORDINATES": {lat: 40.350000, lng: -3.933333},
    },
    "2": {
      "NAME": "BOC",
      "ALTITUDE": 3000,
      "COORDINATES": {lat: 40.3418715195, lng: -3.9752347216},
    },
    "3": {
      "NAME": "ALDEA DEL FRESNO",
      "ALTITUDE": 4500,
      "COORDINATES": {lat: 40.322222, lng: -4.201476},
    },
    "4": {
      "NAME": "ESCALONA GOLF",
      "ALTITUDE": 4500,
      "COORDINATES": {lat: 40.162132, lng: -4.402248},
    },
    "5": {
      "NAME": "BOD (CRUCE NAVALC)",
      "ALTITUDE": 3000,
      "COORDINATES": {lat: 40.266117, lng: -4.039105},
    },
    "6": {
      "NAME": "S",
      "ALTITUDE": 3000,
      "COORDINATES": {lat: 40.313889, lng: -3.841389},
    },   
    "7": {
      "NAME": "LECU",
      "ALTITUDE": 2269,
      "COORDINATES": {lat: 40.370556, lng:-3.785278},
    },
  },
  "EJEMPLO CPL": {
    "0": {
      "NAME": "LECU",
      "ALTITUDE": 2269,
      "COORDINATES": {lat: 40.370556, lng:-3.785278},
    },
    "1": {
      "NAME": "W",
      "ALTITUDE": 3000,
      "COORDINATES": {lat: 40.350000, lng: -3.933333},
    },
    "2": {
      "NAME": "BOC",
      "ALTITUDE": 3000,
      "COORDINATES": {lat: 40.3418715195, lng: -3.9752347216},
    },
    "3": {
      "NAME": "ALDEA DEL FRESNO",
      "ALTITUDE": 4500,
      "COORDINATES": {lat: 40.322222, lng: -4.201476},
    },
    "4": {
      "NAME": "ESCALONA GOLF",
      "ALTITUDE": 4500,
      "COORDINATES": {lat: 40.162132, lng: -4.402248},
    },
    "5": {
      "NAME": "EMBALSE DE CAZALEGAS",
      "ALTITUDE": 4500,
      "COORDINATES": {lat: 40.022422, lng: -4.689274},
    },
    "6": {
      "NAME": "CEBOLLA",
      "ALTITUDE": 4500,
      "COORDINATES": {lat: 39.947121, lng: -4.570484},
    },
    "7": {
      "NAME": "QUISMONDO",
      "ALTITUDE": 4500,
      "COORDINATES": {lat: 40.107094, lng: -4.324579},
    },
    "8": {
      "NAME": "MÉNTRIDA",
      "ALTITUDE": 4500,
      "COORDINATES": {lat: 40.237278, lng: -4.193859},
    },
    "9": {
      "NAME": "BOD (CRUCE DE NAVALCARNERO)",
      "ALTITUDE": 3000,
      "COORDINATES": {lat: 40.266117, lng: -4.039105},
    },
    "10": {
      "NAME": "S",
      "ALTITUDE": 3000,
      "COORDINATES": {lat: 40.313889, lng: -3.841389},
    },   
    "11": {
      "NAME": "LECU",
      "ALTITUDE": 2269,
      "COORDINATES": {lat: 40.370556, lng:-3.785278},
    },
  },
  "VUELTA A LER43": {
    "0": {
      "NAME": "LECU",
      "ALTITUDE": 2269,
      "COORDINATES": {lat: 40.370556, lng:-3.785278},
    },
    "1": {
      "NAME": "W",
      "ALTITUDE": 3000,
      "COORDINATES": {lat: 40.350000, lng: -3.933333},
    },
    "2": {
      "NAME": "EL ÁLAMO",
      "ALTITUDE": 3000,
      "COORDINATES": {lat: 40.230264, lng: -3.993378},
    },
    "3": {
      "NAME": "(BOC) CABAÑAS DE LA SAGRA",
      "ALTITUDE": 3000,
      "COORDINATES": {lat: 40.007745, lng: -3.948805},
    },
    "4": {
      "NAME": "POLÁN",
      "ALTITUDE": 4500,
      "COORDINATES": {lat: 39.788117, lng: -4.167610},
    },
    "5": {
      "NAME": "MALPICA DE TAJO",
      "ALTITUDE": 4500,
      "COORDINATES": {lat: 39.896686, lng: -4.549782},
    },
    "6": {
      "NAME": "CEBOLLA",
      "ALTITUDE": 4500,
      "COORDINATES": {lat: 39.947121, lng: -4.570484},
    },
    "7": {
      "NAME": "QUISMONDO",
      "ALTITUDE": 4500,
      "COORDINATES": {lat: 40.107094, lng: -4.324579},
    },
    "8": {
      "NAME": "MÉNTRIDA",
      "ALTITUDE": 4500,
      "COORDINATES": {lat: 40.237278, lng: -4.193859},
    },
    "9": {
      "NAME": "BOD (CRUCE DE NAVALCARNERO)",
      "ALTITUDE": 3000,
      "COORDINATES": {lat: 40.266117, lng: -4.039105},
    },
    "10": {
      "NAME": "S",
      "ALTITUDE": 3000,
      "COORDINATES": {lat: 40.313889, lng: -3.841389},
    },   
    "11": {
      "NAME": "LECU",
      "ALTITUDE": 2269,
      "COORDINATES": {lat: 40.370556, lng:-3.785278},
    },
  },
  "LECU --> LEZL": {
    "0": {
      "NAME": "LECU",
      "ALTITUDE": 2269,
      "COORDINATES": {lat: 40.370556, lng:-3.785278},
    },
    "1": {
      "NAME": "W",
      "ALTITUDE": 3000,
      "COORDINATES": {lat: 40.350000, lng: -3.933333},
    },
    "2": {
      "NAME": "EL ÁLAMO",
      "ALTITUDE": 3000,
      "COORDINATES": {lat: 40.23041188027539, lng: -3.9912114035541895},
    },
    "3": {
      "NAME": "CHOZAS DE CANALES",
      "ALTITUDE": 3000,
      "COORDINATES": {lat: 40.09909582897189, lng: -4.043658192967996},
    },
    "4": {
      "NAME": "BOC",
      "ALTITUDE": 3000,
      "COORDINATES": {lat: 40.047591462658794, lng: -4.036445617675782},
    },
    "5": {
      "NAME": "BARGAS",
      "ALTITUDE": 4500,
      "COORDINATES": {lat: 39.93946102500782, lng: -4.020139185595327},
    },
    "6": {
      "NAME": "BOC",
      "ALTITUDE": 5500,
      "COORDINATES": {lat: 39.90129867522985, lng: -4.0340988838579515},
    },
    "7": {
      "NAME": "BOC",
      "ALTITUDE": 6500,
      "COORDINATES": {lat: 39.68922382844216, lng: -4.108886718750001},
    },
    "8": {
      "NAME": "EMB CORAZÓN",
      "ALTITUDE": 7500,
      "COORDINATES": {lat: 39.39587712612034, lng: -4.207763671875001},
    },
    "9": {
      "NAME": "HIJ VOR",
      "ALTITUDE": 7500,
      "COORDINATES": {lat: 38.50813409451445, lng: -5.0996893644332895},
    },
    "10": {
      "NAME": "EMB HUESNA",
      "ALTITUDE": 6500,
      "COORDINATES": {lat: 37.77587088725018, lng: -5.694007873535156},
    },
    "11": {
      "NAME": "N",
      "ALTITUDE": 1111,
      "COORDINATES": {lat: 37.550556, lng: -5.885833},
    },
    "12": {
      "NAME": "LEZL",
      "ALTITUDE": 111,
      "COORDINATES": {lat: 37.418056, lng: -5.898889},
    },
  },
  "LEZL --> LECU": {
    "0": {
      "NAME": "LEZL",
      "ALTITUDE": 111,
      "COORDINATES": {lat: 37.418056, lng: -5.898889},
    },
    "1": {
      "NAME": "N",
      "ALTITUDE": 1111,
      "COORDINATES": {lat: 37.550556, lng: -5.885833},
    },
    "2": {
      "NAME": "EMB HUESNA",
      "ALTITUDE": 8500,
      "COORDINATES": {lat: 37.77587088725018, lng: -5.694007873535156},
    },
    "3": {
      "NAME": "HIJ VOR",
      "ALTITUDE": 8500,
      "COORDINATES": {lat: 38.50813409451445, lng: -5.0996893644332895},
    },
    "4": {
      "NAME": "EMB CORAZÓN",
      "ALTITUDE": 8500,
      "COORDINATES": {lat: 39.39587712612034, lng: -4.207763671875001},
    },
    "5": {
      "NAME": "BOD",
      "ALTITUDE": 5500,
      "COORDINATES": {lat: 39.68922382844216, lng: -4.108886718750001},
    },
    "6": {
      "NAME": "BOD",
      "ALTITUDE": 4500,
      "COORDINATES": {lat: 39.90129867522985, lng: -4.0340988838579515},
    },
    "7": {
      "NAME": "BOD (YUNCLILLOS)",
      "ALTITUDE": 3000,
      "COORDINATES": {lat: 40.0221925640183, lng:  -3.9902992495081446},
    },
    "8": {
      "NAME": "S",
      "ALTITUDE": 3000,
      "COORDINATES": {lat: 40.313889, lng: -3.841389},
    },
    "9": {
      "NAME": "LECU",
      "ALTITUDE": 2269,
      "COORDINATES": {lat: 40.370556, lng:-3.785278},
    },
  },
  "LECU --> LEBA": {
    "0": {
      "NAME": "LECU",
      "ALTITUDE": 2269,
      "COORDINATES": {lat: 40.370556, lng:-3.785278},
    },
    "1": {
      "NAME": "W",
      "ALTITUDE": 3000,
      "COORDINATES": {lat: 40.350000, lng: -3.933333},
    },
    "2": {
      "NAME": "EL ÁLAMO",
      "ALTITUDE": 3000,
      "COORDINATES": {lat: 40.23041188027539, lng: -3.9912114035541895},
    },
    "3": {
      "NAME": "CHOZAS DE CANALES",
      "ALTITUDE": 3000,
      "COORDINATES": {lat: 40.09909582897189, lng: -4.043658192967996},
    },
    "4": {
      "NAME": "BOC",
      "ALTITUDE": 3000,
      "COORDINATES": {lat: 40.047591462658794, lng: -4.036445617675782},
    },
    "5": {
      "NAME": "BARGAS",
      "ALTITUDE": 4500,
      "COORDINATES": {lat: 39.93946102500782, lng: -4.020139185595327},
    },
    "6": {
      "NAME": "BOC",
      "ALTITUDE": 5500,
      "COORDINATES": {lat: 39.90129867522985, lng: -4.0340988838579515},
    },
    "7": {
      "NAME": "BOC",
      "ALTITUDE": 6500,
      "COORDINATES": {lat: 39.68922382844216, lng: -4.108886718750001},
    },
    "8": {
      "NAME": "EMBALSE CORAZÓN",
      "ALTITUDE": 7500,
      "COORDINATES": {lat: 39.39587712612034, lng: -4.207763671875001},
    },
    "9": {
      "NAME": "EL CASTAÑO",
      "ALTITUDE": 7500,
      "COORDINATES": {lat: 39.013901950242214, lng: -4.387621117003271},
    },
    "10": {
      "NAME": "VILLANUEVA DE CÓRDOBA",
      "ALTITUDE": 7500,
      "COORDINATES": {lat: 38.322534, lng: -4.628508},
    },
    "11": {
      "NAME": "EMBALSE DE GUADALMELLATO",
      "ALTITUDE": 5000,
      "COORDINATES": {lat: 38.041780, lng: -4.672955},
    },
    "12": {
      "NAME": "E",
      "ALTITUDE": 1300,
      "COORDINATES": {lat: 37.860556, lng: -4.768056},
    },
    "13": {
      "NAME": "LEBA",
      "ALTITUDE": 307,
      "COORDINATES": {lat: 37.841944, lng: -4.848889},
    },
  },
  "LEBA --> LECU": {
    "0": {
      "NAME": "LEBA",
      "ALTITUDE": 307,
      "COORDINATES": {lat: 37.841944, lng: -4.848889},
    },
    "1": {
      "NAME": "E",
      "ALTITUDE": 1300,
      "COORDINATES": {lat: 37.860556, lng: -4.768056},
    },
    "2": {
      "NAME": "EMBALSE DE GUADALMELLATO",
      "ALTITUDE": 4000,
      "COORDINATES": {lat: 38.041780, lng: -4.672955},
    },
    "3": {
      "NAME": "VILLANUEVA DE CÓRDOBA",
      "ALTITUDE": 8500,
      "COORDINATES": {lat: 38.322534, lng: -4.628508},
    },
    "4": {
      "NAME": "EL CASTAÑO",
      "ALTITUDE": 8500,
      "COORDINATES": {lat: 39.013901950242214, lng: -4.387621117003271},
    },
    "5": {
      "NAME": "EMBALSE CORAZÓN",
      "ALTITUDE": 8500,
      "COORDINATES": {lat: 39.39587712612034, lng: -4.207763671875001},
    },
    "6": {
      "NAME": "BOD",
      "ALTITUDE": 5500,
      "COORDINATES": {lat: 39.68922382844216, lng: -4.108886718750001},
    },
    "7": {
      "NAME": "BOD",
      "ALTITUDE": 4500,
      "COORDINATES": {lat: 39.90129867522985, lng: -4.0340988838579515},
    },
    "8": {
      "NAME": "BOD (YUNCLILLOS)",
      "ALTITUDE": 3000,
      "COORDINATES": {lat: 40.0221925640183, lng:  -3.9902992495081446},
    },
    "9": {
      "NAME": "S",
      "ALTITUDE": 3000,
      "COORDINATES": {lat: 40.313889, lng: -3.841389},
    },
    "10": {
      "NAME": "LECU",
      "ALTITUDE": 2269,
      "COORDINATES": {lat: 40.370556, lng:-3.785278},
    },
  },
  "LEZL --> LEBA": {
    "0": {
      "NAME": "LEZL",
      "ALTITUDE": 111,
      "COORDINATES": {lat: 37.418056, lng: -5.898889},
    },
    "1": {
      "NAME": "N",
      "ALTITUDE": 1111,
      "COORDINATES": {lat: 37.550556, lng: -5.885833},
    },
    "2": {
      "NAME": "PEÑAFLOR",
      "ALTITUDE": 4500,
      "COORDINATES": {lat: 37.709912, lng: -5.345825},
    },
    "3": {
      "NAME": "W",
      "ALTITUDE": 1300,
      "COORDINATES": {lat: 37.831667, lng: -4.928889},
    },   
    "4": {
      "NAME": "LEBA",
      "ALTITUDE": 307,
      "COORDINATES": {lat: 37.841944, lng: -4.848889},
    },
  },
  "LEBA --> LEZL": {   
    "0": {
      "NAME": "LEBA",
      "ALTITUDE": 307,
      "COORDINATES": {lat: 37.841944, lng: -4.848889},
    },
    "1": {
      "NAME": "W",
      "ALTITUDE": 1300,
      "COORDINATES": {lat: 37.831667, lng: -4.928889},
    },
    "2": {
      "NAME": "PEÑAFLOR",
      "ALTITUDE": 4500,
      "COORDINATES": {lat: 37.709912, lng: -5.345825},
    },
    "3": {
      "NAME": "N",
      "ALTITUDE": 1111,
      "COORDINATES": {lat: 37.550556, lng: -5.885833},
    },
    "4": {
      "NAME": "LEZL",
      "ALTITUDE": 111,
      "COORDINATES": {lat: 37.418056, lng: -5.898889},
    },    
  },
  "LECU --> LEVD": {
    "0": {
      "NAME": "LECU",
      "ALTITUDE": 2269,
      "COORDINATES": {lat: 40.370556, lng:-3.785278},
    },
    "1": {
      "NAME": "W",
      "ALTITUDE": 3000,
      "COORDINATES": {lat: 40.350000, lng: -3.933333},
    },
    "2": {
      "NAME": "VILLANUEVA DE LA CAÑADA",
      "ALTITUDE": 3000,
      "COORDINATES": {lat: 40.47045733758853, lng: -3.9935302734375004},
    },
    "3": {
      "NAME": "PUNTA N EMB VALMAYOR",
      "ALTITUDE": 4500,
      "COORDINATES": {lat: 40.5894170146934, lng: -4.049577713012696},
    },
    "4": {
      "NAME": "ALTO DEL LEÓN",
      "ALTITUDE": 6500,
      "COORDINATES": {lat: 40.70797026199809, lng: -4.140729904174806},
    },
    "5": {
      "NAME": "NAVA DE LA ASUNCIÓN",
      "ALTITUDE": 6500,
      "COORDINATES": {lat: 41.15629809205029, lng: -4.487743377685548},
    },
    "6": {
      "NAME": "S",
      "ALTITUDE": 3700,
      "COORDINATES": {lat: 41.2945, lng: -4.6811},
    },
    "7": {
      "NAME": "S-1",
      "ALTITUDE": 3700,
      "COORDINATES": {lat: 41.6631, lng: -4.7864},
    },
    "8": {
      "NAME": "LEVD",
      "ALTITUDE": 2775,
      "COORDINATES": {lat: 41.706111, lng: -4.851944},
    },
  },
  "LEVD --> LECU": {    
    "0": {
      "NAME": "LEVD",
      "ALTITUDE": 2775,
      "COORDINATES": {lat: 41.706111, lng: -4.851944},
    },
    "1": {
      "NAME": "S-1",
      "ALTITUDE": 3700,
      "COORDINATES": {lat: 41.6631, lng: -4.7864},
    },
    "2": {
      "NAME": "S",
      "ALTITUDE": 3700,
      "COORDINATES": {lat: 41.2945, lng: -4.6811},
    },
    "3": {
      "NAME": "NAVA DE LA ASUNCIÓN",
      "ALTITUDE": 6500,
      "COORDINATES": {lat: 41.15629809205029, lng: -4.487743377685548},
    },
    "4": {
      "NAME": "ALTO DEL LEÓN",
      "ALTITUDE": 6500,
      "COORDINATES": {lat: 40.70797026199809, lng: -4.140729904174806},
    },
    "5": {
      "NAME": "PUNTA N EMB VALMAYOR",
      "ALTITUDE": 4500,
      "COORDINATES": {lat: 40.5894170146934, lng: -4.049577713012696},
    },
    "6": {
      "NAME": "VILLANUEVA DE LA CAÑADA",
      "ALTITUDE": 3000,
      "COORDINATES": {lat: 40.47045733758853, lng: -3.9935302734375004},
    },
    "7": {
      "NAME": "N",
      "ALTITUDE": 3000,
      "COORDINATES": {lat: 40.395664, lng: -3.890284},
    },
    "8": {
      "NAME": "LECU",
      "ALTITUDE": 2269,
      "COORDINATES": {lat: 40.370556, lng:-3.785278},
    }
  },
  "LECU --> LEBG": {
    "0": {
      "NAME": "LECU",
      "ALTITUDE": 2269,
      "COORDINATES": {lat: 40.370556, lng:-3.785278},
    },
    "1": {
      "NAME": "W",
      "ALTITUDE": 3000,
      "COORDINATES": {lat: 40.350000, lng: -3.933333},
    },
    "2": {
      "NAME": "BOC (V.CAÑADA/PARDILLO)",
      "ALTITUDE": 3000,
      "COORDINATES": {lat: 40.468622, lng: -3.991765},
    },
    "3": {
      "NAME": "P.N. EMB VALMAYOR",
      "ALTITUDE": 4500,
      "COORDINATES": {lat: 40.577114, lng: -4.046218},
    },
    "4": {
      "NAME": "ALTO DEL LEÓN",
      "ALTITUDE": 6500,
      "COORDINATES": {lat: 40.709975, lng: -4.139909},
    },
    "5": {
      "NAME": "LOS ÁNGELES DE S. RAFAEL",
      "ALTITUDE": 6500,
      "COORDINATES": {lat: 40.777521, lng: -4.222185},
    },
    "6": {
      "NAME": "ARANDA DE DUERO",
      "ALTITUDE": 8500,
      "COORDINATES": {lat: 41.668148, lng: -3.691749},
    },
    "7": {
      "NAME": "E",
      "ALTITUDE": 3500,
      "COORDINATES": {lat: 42.286944, lng: -3.550556},
    },
    "8": {
      "NAME": "LEBG",
      "ALTITUDE": 2962,
      "COORDINATES": {lat: 42.357500, lng: -3.613611},
    },

  },
  "LEBG --> LECU": {
    "0": {
      "NAME": "LEBG",
      "ALTITUDE": 2962,
      "COORDINATES": {lat: 42.357500, lng: -3.613611},
    },
    "1": {
      "NAME": "E",
      "ALTITUDE": 3500,
      "COORDINATES": {lat: 42.286944, lng: -3.550556},
    },
    "2": {
      "NAME": "ARANDA DE DUERO",
      "ALTITUDE": 8500,
      "COORDINATES": {lat: 41.668148, lng: -3.691749},
    },
    "3": {
      "NAME": "LOS ÁNGELES DE S. RAFAEL",
      "ALTITUDE": 6500,
      "COORDINATES": {lat: 40.777521, lng: -4.222185},
    },
    "4": {
      "NAME": "ALTO DEL LEÓN",
      "ALTITUDE": 6500,
      "COORDINATES": {lat: 40.709975, lng: -4.139909},
    },
    "5": {
      "NAME": "P.N. EMB VALMAYOR",
      "ALTITUDE": 4500,
      "COORDINATES": {lat: 40.577114, lng: -4.046218},
    },
    "6": {
      "NAME": "SILO DE NAVALCARNERO",
      "ALTITUDE": 3000,
      "COORDINATES": {lat: 40.303355, lng: -4.008877},
    },
    "7": {
      "NAME": "S",
      "ALTITUDE": 3000,
      "COORDINATES": {lat: 40.313889, lng: -3.841389},
    },
    "8": {
      "NAME": "LECU",
      "ALTITUDE": 2269,
      "COORDINATES": {lat: 40.370556, lng:-3.785278},
    },
    
    
    
    
    
    
    
    

  },
  "LECU --> LEBZ": {
    "0": {
      "NAME": "LECU",
      "ALTITUDE": 2269,
      "COORDINATES": {lat: 40.370556, lng:-3.785278},
    },
    "1": {
      "NAME": "W",
      "ALTITUDE": 3000,
      "COORDINATES": {lat: 40.350000, lng: -3.933333},
    },
    "2": {
      "NAME": "BOC",
      "ALTITUDE": 3000,
      "COORDINATES": {lat: 40.3418715195, lng: -3.9752347216},
    },
    "3": {
      "NAME": "MÉNTRIDA",
      "ALTITUDE": 4500,
      "COORDINATES": {lat: 40.2378577272414, lng:-4.194670985781146},
    },
    "4": {
      "NAME": "CERRO ALBERCHE",
      "ALTITUDE": 4500,
      "COORDINATES": {lat: 40.06151934529636, lng: -4.523620605468751},
    },
    "5": {
      "NAME": "EMB AZUTÁN",
      "ALTITUDE": 7500,
      "COORDINATES": {lat: 39.7752972152002, lng: -5.090103149414063},
    },
    "6": {
      "NAME": "SANTA CRUZ DE LA SIERRA",
      "ALTITUDE": 7500,
      "COORDINATES": {lat: 39.33600723827481, lng: -5.846245521605847},
    },
    "7": {
      "NAME": "MONTIJO (E)",
      "ALTITUDE": 1608,
      "COORDINATES": {lat: 38.909802713674544, lng: -6.615829467773438},
    },
    "8": {
      "NAME": "LEBZ",
      "ALTITUDE": 608,
      "COORDINATES": {lat: 38.890966020877165, lng: -6.820814609527588},
    },
    
  },
  "LEBZ --> LECU": {
    "0": {
      "NAME": "LEBZ",
      "ALTITUDE": 608,
      "COORDINATES": {lat: 38.890966020877165, lng: -6.820814609527588},
    },
    "1": {
      "NAME": "MONTIJO (E)",
      "ALTITUDE": 1608,
      "COORDINATES": {lat: 38.909802713674544, lng: -6.615829467773438},
    },
    "2": {
      "NAME": "SANTA CRUZ DE LA SIERRA",
      "ALTITUDE": 7500,
      "COORDINATES": {lat: 39.33600723827481, lng: -5.846245521605847},
    },
    "3": {
      "NAME": "EMB AZUTÁN",
      "ALTITUDE": 7500,
      "COORDINATES": {lat: 39.7752972152002, lng: -5.090103149414063},
    },
    "4": {
      "NAME": "CERRO ALBERCHE",
      "ALTITUDE": 4500,
      "COORDINATES": {lat: 40.06151934529636, lng: -4.523620605468751},
    },
    "5": {
      "NAME": "MÉNTRIDA",
      "ALTITUDE": 4500,
      "COORDINATES": {lat: 40.2378577272414, lng:-4.194670985781146},
    },
    "6": {
      "NAME": "CRUCE NAVALCARNERO",
      "ALTITUDE": 3000,
      "COORDINATES": {"lat":40.26577343239632,"lng":-4.039106369018556},
    },
    "7": {
      "NAME": "S",
      "ALTITUDE": 3000,
      "COORDINATES": {lat: 40.313889, lng: -3.841389},
    },
    "8": {
      "NAME": "LECU",
      "ALTITUDE": 2269,
      "COORDINATES": {lat: 40.370556, lng:-3.785278},
    },






    
  },
}

export default rutas