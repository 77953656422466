import { useEffect } from "react";
import InputTable from "./InputTable";
import { Link } from "react-router-dom";

import PDFPMENTOR from "./PDFPMENTOR.jsx";
import PDFPA28ARROW from "./PDFPA28ARROW.jsx";
import PDFDA40 from "./PDFDA40.jsx";
import PDFSENECA2 from "./PDFSENECA2.jsx";
import PDFSENECA3 from "./PDFSENECA3.jsx";
import PDFP2006T from "./PDFP2006T.jsx";
import PDFC172 from "./PDFC172.jsx";
import { ReactComponent as LogoSvg } from "./PDFTemplates/logo.svg";

function ModelSelect({
  data,
  modelData,
  setModelData,
  handleChange,
  inputData,
}) {
  const modelos = Object.keys(data);

  useEffect(() => {
    if (modelData.modelo && modelData.matricula) {
      setModelData((prevState) => ({
        ...prevState,
        bag1: data[modelData.modelo].MBDATA.bag1,
        bag2: data[modelData.modelo].MBDATA.bag2,
        bag3: data[modelData.modelo].MBDATA.bag3,
        bag4: data[modelData.modelo].MBDATA.bag4,
        fob: data[modelData.modelo].MBDATA.fob,
        fpax: data[modelData.modelo].MBDATA.fpax,
        oilna: data[modelData.modelo].MBDATA.oilna,
        rpax1: data[modelData.modelo].MBDATA.rpax1,
        rpax2: data[modelData.modelo].MBDATA.rpax2,
        rpax3: data[modelData.modelo].MBDATA.rpax3,
        cg: data[modelData.modelo].MATRICULAS[modelData.matricula].CG,
        tipo: data[modelData.modelo].MATRICULAS[modelData.matricula].TIPO,
        momento: data[modelData.modelo].MATRICULAS[modelData.matricula].MOMENTO,
        peso: data[modelData.modelo].MATRICULAS[modelData.matricula].PESO,
      }));
    }
  }, [modelData.matricula, setModelData, modelData.modelo, data]);

  return (
    <div className="main-container">
      <div className="nav-container">
        <Link to="/">
          <button className="nav-logo">
            <LogoSvg />
          </button>
        </Link>
        <div className="nav-buttons">
          <Link to="/flightplan">
            <button className="button">Flight Plan</button>
          </Link>
          <Link to="/massandbalance">
            <button className="button active-page-btn btn-mb">
              Mass & Balance
            </button>
          </Link>
          <Link to="/meteo">
            <button className="button">Meteo</button>
          </Link>
        </div>
        <a
          href="https://www.paypal.com/donate/?hosted_button_id=RAZU6S7ZT5DS2"
          target="_blank"
          rel="noreferrer"
        >
          <button className="boton-generar-pdf">Donate</button>
        </a>
      </div>
      <div className="select-model-container">
        <label htmlFor="modelo">Select a model</label>
        <select
          id="modelo"
          name="modelo"
          onChange={handleChange}
          value={modelData.modelo}
        >
          <option value="">---</option>
          {modelos.map((val) => (
            <option value={val} key={val}>
              {val}
            </option>
          ))}
        </select>
        {modelData.modelo && (
          <div>
            <label htmlFor="matricula">Select a registration number</label>
            <select
              id="matricula"
              name="matricula"
              onChange={handleChange}
              value={modelData.matricula}
            >
              <option value="">---</option>
              {Object.keys(
                data[modelos[modelos.indexOf(modelData.modelo)]]["MATRICULAS"]
              ).map((val) => (
                <option value={val} key={val}>
                  {val}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>
      <div className="mb-container">
        {modelData.modelo && modelData.matricula && (
          <InputTable
            inputData={inputData}
            modelData={modelData}
            handleChange={handleChange}
          />
        )}

        {modelData.modelo &&
          modelData.matricula &&
          (() => {
            switch (modelData.modelo) {
              case "Cessna 172":
                return <PDFC172 modelData={modelData} inputData={inputData} />;

              case "Tecnam P-Mentor":
                return (
                  <PDFPMENTOR modelData={modelData} inputData={inputData} />
                );

              case "PA28 Arrow":
                return (
                  <PDFPA28ARROW inputData={inputData} modelData={modelData} />
                );

              case "Diamond 40":
                return <PDFDA40 inputData={inputData} modelData={modelData} />;

              case "Tecnam P2006T":
                return (
                  <PDFP2006T inputData={inputData} modelData={modelData} />
                );

              case "PA34 SENECA":
                if (modelData.matricula === "HOZ") {
                  return (
                    <PDFSENECA2 inputData={inputData} modelData={modelData} />
                  );
                }

                return (
                  <PDFSENECA3 inputData={inputData} modelData={modelData} />
                );

              default:
                return;
            }
          })()}
      </div>
      <footer className="about">
        <Link to="/about" target="_blank" rel="noopener noreferrer">
          <button className="about-btn">About the authors</button>
        </Link>
      </footer>
    </div>
  );
}

export default ModelSelect;
