import { React, useState } from "react";
import { Routes, Route, Link } from "react-router-dom";
import data from "./components/PerformanceData/AircraftData.js";

import FlightPlan from "./components/FlightPlan.jsx";
import MassAndBalance from "./components/MassAndBalance.jsx";
import Layout from "./components/Layout.jsx";
import Meteo from "./components/Meteo.jsx";
import About from "./components/About.jsx";

function App() {
  const initialState = {
    p1: 0,
    p2: 0,
    bag1: 0,
    bag2: 0,
    bag3: 0,
    bag4: 0,
    fob: 0,
    oilna: 0,
    rpax1: 0,
    rpax2: 0,
    rpax3: 0,
    rpax4: 0,
    tf: 0,
    DEPElev: 0,
    ARRElev: 0,
    DEPTemp: 0,
    ARRTemp: 0,
    DEPQNH: 0,
    ARRQNH: 0,
  };

  const [modelData, setModelData] = useState({});
  const [inputData, setInputData] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "modelo") {
      setModelData((prevState) => ({
        ...prevState,
        matricula: "",
        [name]: value,
      }));
      setInputData(initialState);
    } else if (name === "matricula") {
      setModelData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else {
      setInputData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  return (
    <div>
      <Routes>
        <Route path="/" element={<Layout />}></Route>
        <Route
          path="/flightplan"
          element={
            <FlightPlan
              data={data}
              modelData={modelData}
              setModelData={setModelData}
              handleChange2={handleChange}
            />
          }
        />
        <Route
          path="/massandbalance"
          element={
            <MassAndBalance
              data={data}
              inputData={inputData}
              modelData={modelData}
              setModelData={setModelData}
              handleChange={handleChange}
            />
          }
        />
        <Route path="/meteo" element={<Meteo />} />
        <Route path="/about" element={<About />} />
      </Routes>
      <footer className="about">
        <Link
          to="https://twitter.com/rjbb747"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="photographer-btn">© K.O.</button>
        </Link>
      </footer>
    </div>
  );
}

export default App;
